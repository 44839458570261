import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as React from 'react';
import { useEffect, useState } from 'react';
import useTranslation from '../i18n/LanguageHook';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        deliveryInstructions: {
            width: '100%',
            margin: 0,
            marginBottom: theme.spacing(1),
        },
        input: {
            fontSize: '1rem',
        },

    }),
);


const DeliveryInstructions = (props) => {
    const classes = useStyles();
    const translation: any = useTranslation();
    const theme = useTheme();
    const xssm = useMediaQuery(theme.breakpoints.up('sm'));
    const [deliveryInstructions, setDeliveryInstructions] = useState('');

    useEffect(() => {
        props.childRef.current = deliveryInstructions;
    }, [deliveryInstructions, props.childRef]);

    return (
        <TextField
            id="outlined-multiline-static"
            label={translation.addresses.delivery_instructions}
            multiline
            fullWidth
            rows={xssm ? 8 : 4}
            rowsMax={xssm ? 8 : 4}
            variant="filled"
            InputProps={{
                classes: {
                    input: classes.input,
                }
            }}
            className={classes.deliveryInstructions}
            value={deliveryInstructions}
            onChange={event => setDeliveryInstructions(event.target.value)}
        />
    );
};

export default DeliveryInstructions;