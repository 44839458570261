/* =============================
   Custom React hook > it returns translation object
================================ */
import LocalizedStrings from "react-localization";
import localization from "./localization";
import { useLanguageContext } from "./LanguageContext";

const useTranslation = () => {
    const { language } = useLanguageContext();
    let translation = new LocalizedStrings(localization);

    translation.setLanguage(language);
    return translation;
};

export default useTranslation;