import * as React from 'react';
import { Toaster } from 'react-hot-toast';
import {
  BrowserRouter as Router,
  Redirect, Route, Switch
} from 'react-router-dom';
import LanguageContextProvider from './Pages/i18n/LanguageContext';
import Login from './Pages/Login';
import OrderCreate from './Pages/Protected/OrderCreate';
import OrderHistory from './Pages/Protected/OrderHistory';
import Profile from './Pages/Protected/Profile';
import UserWrapper from './Pages/UserContext';

const toastStyles = {
  style: {
    margin: '50px',
  },
  success: {
    style: {
      background: '#D4EDDA',
      border: '1px solid #C3E6CB',
      duration: 5000,
    },
  },
  error: {
    style: {
      background: '#F9D7DA',
      border: '1px solid #F6C6CB',
    },
  },
};

const NotFound = () => <Redirect to="/" />;

const App = () => {
  return (
    <LanguageContextProvider>
      <UserWrapper>
        {/* <Router basename="/shop"> */}
        <Router>
          <div>
            <Toaster position="bottom-center" toastOptions={toastStyles} />
            <Switch>
              <Route exact path={["/", "/login"]} component={Login} />
              <Route path="/orders" component={OrderHistory} />
              <Route path="/create" component={OrderCreate} />
              <Route path="/profile" component={Profile} />
              <Route component={NotFound} />
            </Switch>
          </div>
        </Router>
      </UserWrapper>
    </LanguageContextProvider>
  );
};

export default App;
