import DateFnsUtils from '@date-io/date-fns';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  KeyboardDatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import * as React from 'react';
import { useEffect, useState } from 'react';
import useTranslation from '../i18n/LanguageHook';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orderConfirmDate: {
      fontSize: '10px',
      width: '100%',
      minWidth: '100%',
      maxWidth: '100%',
      margin: theme.spacing(1, 0),
    },
    orderConfirmFormText: {
      fontSize: '1rem',
      textAlign: 'center',
    },
    orderConfirmDatePicker: {
      [theme.breakpoints.only('xs')]: {
        '& .MuiPickersModal-dialogRoot': {
          transform: 'scale(1)'
        },
      },
    },
  }),
);


const DeliveryDatePicker = (props) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const classes = useStyles();
  const translation: any = useTranslation();
  const { setDeliveryDate } = props;

  useEffect(() => {
    setDeliveryDate(selectedDate);
  }, [selectedDate, setDeliveryDate]);

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          clearable
          id="date-picker-dialog"
          label={translation.orders.delivery_date}
          variant="dialog"
          inputVariant="outlined"
          format="dd/MM/yyyy"
          disablePast={true}
          minDateMessage={translation.notification.delivery_date_error}
          className={classes.orderConfirmDate}
          value={selectedDate}
          onChange={newDate => {
            setSelectedDate(newDate);
          }}
          InputProps={{
            classes: {
              input: classes.orderConfirmFormText,
            }
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.orderConfirmFormText,
            }
          }}
          DialogProps={{
            className: classes.orderConfirmDatePicker,
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

export default DeliveryDatePicker;