import { FormControlLabel, Checkbox } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import jwt_decode from 'jwt-decode';
import * as React from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Redirect, useHistory } from 'react-router-dom';
import { login, logout } from './Api';
import useTranslation from './i18n/LanguageHook';
import icebreaker_logo from './Media/icebreaker_logo_transparent.png';
import { useUserContext } from './UserContext';


const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('smartphone')]: {
      maxWidth: '444px',
    },
    [theme.breakpoints.down('smartphone')]: {
      maxWidth: '100%',
      margin: 0,
      padding: 0,
      alignItems: 'start',
      height: '100%',
    }
  },
  loginbox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#ffffff',
    borderRadius: 0,
    minHeight: '600px',
    height: '100vh',
    [theme.breakpoints.up('smartphone')]: {
      marginTop: 'max(0px, calc(0.5 * calc(50vh - 200px)))',
      maxWidth: '444px',
      borderRadius: '5%',
      height: '100%',
    },
  },
  logo: {
    width: '100%',
    borderRadius: '0px 0px 50% 50% / 0px 0px 50% 50%',
    [theme.breakpoints.up('smartphone')]: {
      borderRadius: '5% 5% 50% 50% / 5% 5% 50% 50%',
    },
  },
  logoWrapper: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '0px 0px 50% 50% / 0px 0px 50% 50%',
    [theme.breakpoints.up('smartphone')]: {
      borderRadius: '5% 5% 50% 50% / 5% 5% 50% 50%',
    },
    paddingBottom: '10%',
    // boxShadow: '0 2px 10px -1px rgba(0,0,0, .25)',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '10%',
    margin: theme.spacing(1, 5),
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('smartphone')]: {
      marginTop: theme.spacing(4),
    },
  },
  submit: {
    borderRadius: '20em',
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1),
    width: '60%',
    [theme.breakpoints.down('mobile')]: {
      margin: theme.spacing(2, 0, 1),
    },
    fontWeight: 600,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "@media (pointer: fine)": {
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
  copyright: {
    fontSize: '0.7rem'
  },
  sign_in: {
    margin: theme.spacing(1, 0),
  },
  inputRoot: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '20em',
    padding: theme.spacing(0, 2),
    fontWeight: 800,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "@media (pointer: fine)": {
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
    },
    "&&&:before": {
      borderBottom: "none",
    },
    "&&&:after": {
      borderBottom: "none",
    },
  },
  inputFocused: {
    backgroundColor: `${theme.palette.primary.dark} !important`,
  },
  inputShrink: {
    color: `${theme.palette.primary.light} !important`,
    transformOrigin: 'top left',
  },
  inputLabel: {
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
    marginLeft: '10%',
  },
  username: {
    borderRadius: '20em',
  },
}));

const Copyright = () => {
  const classes = useStyles();
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className={classes.copyright}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://icebreakersrl.it/">
        Icebreakersrl.it
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const translation: any = useTranslation();
  const mobilescreen = useMediaQuery('(max-width:350px)');
  const smartphone = useMediaQuery('(max-width:450px)');
  const { user, setUser }: any = useUserContext();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [remember, setRemember] = useState(false);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const loginUser = {
      username: username,
      password: password,
      remember: remember,
    };

    login(loginUser)
      .then(response => {
        if (response?.status === 200) {
          const decoded: any = jwt_decode(response.data['access_token']);
          const JSONdecoded = JSON.parse(decoded.sub);
          if (!JSONdecoded.company_name) {
            logout()
              .then(() => {
                setUser(null);
                history.push(`/`);
              })
              .catch(e => { })
          }
          setUser({
            username: JSONdecoded.username,
            company_name: JSONdecoded.company_name,
          });
          history.push(`/orders`);
        }
        else if (response?.status === 500) {
          // toast.error(translation.notification.http_error);
        }
        else {
          toast.error(translation.notification.sign_in_error);
        }
      })
      .catch(error => {
        // toast.error(translation.notification.http_error);
      });

  };

  if (user) {
    return <Redirect to="/orders" />;
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      disableGutters={smartphone ? true : false}
      className={classes.wrapper}
    >

      <div
        className={classes.loginbox}
      >

        {/* ICEBREAKER LOGO */}
        <div className={classes.logoWrapper}>
          <img src={icebreaker_logo} alt="Icebreaker logo" className={classes.logo} />
        </div>

        <form
          className={classes.form}
          onSubmit={event => { handleSubmit(event) }}
        >

          <TextField
            variant="filled"
            required
            margin={mobilescreen ? "dense" : "normal"}
            style={{ width: '80%' }}
            id="username"
            label={translation.login.username}
            name="username"
            autoComplete="username"
            InputProps={{
              classes: {
                root: classes.inputRoot,
                focused: classes.inputFocused,
              },
              startAdornment: (
                <InputAdornment position="start" style={{ marginTop: 8 }}>
                  <AccountCircle />
                </InputAdornment>
              )
            }}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                shrink: classes.inputShrink,
              }
            }}
            value={username}
            onChange={event => setUsername(event.target.value)}
            autoFocus
          />

          <TextField
            variant="filled"
            required
            margin={mobilescreen ? "dense" : "normal"}
            style={{ width: '80%' }}
            name="password"
            label={translation.login.password}
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            InputProps={{
              classes: {
                root: classes.inputRoot,
                focused: classes.inputFocused,
              },
              startAdornment: (
                <InputAdornment position="start" style={{ marginTop: 8 }}>
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" style={{ marginTop: 8 }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={event => event.preventDefault()}
                  >
                    {showPassword ? <Visibility style={{ color: 'white' }} /> : <VisibilityOff style={{ color: 'white' }} />}
                  </IconButton>
                </InputAdornment>
              )
            }}

            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                shrink: classes.inputShrink,
              }
            }}
            value={password}
            onChange={event => setPassword(event.target.value)}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label={translation.login.remember}
            checked={remember}
            onChange={() => setRemember(!remember)}
          /><br />
          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            disableFocusRipple
            className={classes.submit}
          >
            {translation.action.sign_in}
          </Button>


        </form>
        <Box mt={2}>
          <Copyright />
        </Box>
      </div>

    </Container>
  );
};

export default Login;