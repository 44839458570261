import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Address, Contact, Customer } from '../../types/types';
import { delete_address, delete_contact, get_addresses, get_contacts, get_user, logout, save_address, save_contact, update_user } from '../Api';
import { AddressCreate, AddressPicker } from '../Components/AddressPicker';
import { ContactCreate, ContactPicker } from '../Components/ContactPicker';
import IcePage from '../Components/IcePage';
import { addressFormatting } from '../Components/utils';
import useTranslation from '../i18n/LanguageHook';
import { useUserContext } from '../UserContext';

const useStyles = makeStyles((theme: Theme) => {
  return (
    createStyles({
      profileGrid: {
        maxWidth: 900,
        padding: theme.spacing(1, 2, 4, 2),
      },
      profileGridTitle: {

      },
      profileTitle: {
        fontSize: 'clamp(1.15rem, 5vw, 2rem)',
      },
      profileGridSection: {
        borderLeft: `solid 5px ${theme.palette.primary.dark}`,
        margin: theme.spacing(1),
        padding: theme.spacing(1),
      },
      profileGridSectionNew: {
        borderLeft: `solid 5px ${theme.palette.secondary.main}`,
        margin: theme.spacing(1),
        padding: theme.spacing(1),
      },
      profileGridRow: {
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
        [theme.breakpoints.down('xs')]: {
          display: 'block',
          textAlign: 'center'
        }
      },
      iconButtonBookmark: {
        color: theme.palette.primary.main,
      },
      iconButtonDelete: {
        color: theme.palette.error.main,
      },
      profileText: {
        fontSize: '1rem',
        display: 'inline',
      },
      contactBlock: {
        fontSize: '1rem',
      },
      profileGridPhone: {
        display: 'flex',
        alignItems: 'flex-end',
        '@media (max-width: 450px)': {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      },
      profileAddress: {
        display: 'inline',
        margin: theme.spacing(0, 0, 0, 3),
      },
      profileAddressList: {
        margin: theme.spacing(0, 0, 0, 3)
      },
      profileGridAddressSub: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
        },
      },
      profileContact: {
        margin: theme.spacing(0, 0, 0, 3),
      },
      profileContactList: {
        margin: theme.spacing(0, 0, 0, 3)
      },
      profileGridContactSub: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
        },
        marginBottom: theme.spacing(1),
      },
      profileClickable: {
        color: theme.palette.secondary.main,
        fontStyle: 'italic',
        fontWeight: 500,
        fontSize: '0.9rem',
        display: 'inline',
        cursor: 'pointer',
        // margin: 'auto',
        '& > span': {
          color: theme.palette.error.light,
        },
        transition: '0.3s',
        '&:hover': {
          color: theme.palette.secondary.light,
          '& > span': {
            color: theme.palette.error.light,
          },
        },
        "@media (pointer: fine)": {
          '&:hover': {
            color: theme.palette.secondary.dark,
            '& > span': {
              color: theme.palette.error.dark,
            },
          },
        },
      },
      specialButton: {
        fontWeight: 600,
        display: 'inline-block',
        borderRadius: '20em',
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.error.main,
        },
        "@media (pointer: fine)": {
          '&:hover': {
            backgroundColor: theme.palette.error.dark,
          },
        },
      },
      actionButton: {
        fontWeight: 600,
        display: 'inline-block',
        borderRadius: '20em',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
        "@media (pointer: fine)": {
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
        },
        "@media (max-width: 450px)": {
          width: '60%',
          display: 'block',
          margin: '0 auto',
        },
      },
      profileTelephone: {},
      addressButton: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
          width: '60%',
          display: 'block',
          margin: 'auto',
          marginTop: theme.spacing(1),
        },
      },
      logoutButton: {
        justifySelf: 'center',
        padding: theme.spacing(1, 4),
        margin: theme.spacing(1),
      }
    })
  )
});

const Profile = () => {
  const { user, setUser }: any = useUserContext();
  const classes = useStyles();
  const translation: any = useTranslation();
  const history = useHistory();

  const [userInfo, setUserInfo] = useState<Customer>();

  const [addressList, setAddressList] = useState<Array<Address>>([]);
  const shippingAddress = useRef<string | undefined>("");
  const [addAddress, setAddAddress] = useState(false);
  const newAddress = useRef<Address | undefined>();
  const selectedAddress = useRef<string | undefined>("");


  const [contactList, setContactList] = useState<Array<Contact>>([]);
  const mainContact = useRef<string | undefined>("");
  const [addContact, setAddContact] = useState(false);
  const newContact = useRef<Contact | undefined>();
  const selectedContact = useRef<string | undefined>("");


  // GET CUSTOMER INFORMATION
  const getCustomerInfo = () => {
    get_user()
      .then(response => {
        if (response?.status === 200) {
          setUserInfo(response.data);
        }
        else if (response?.status === 500) {
          // toast.error(translation.notification.http_error);
        }
        else {
          // Mute because customer has no addresses
        }
      })
      .catch(error => {
        // toast.error(translation.notification.http_error);
      });
  };

  useEffect(() => {
    return getCustomerInfo();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //@ts-ignore
    mainContact.current = userInfo?.main_contact;
    shippingAddress.current = userInfo?.shipping_address;
  }, [userInfo]);

  const logOut = (event: any) => {
    event.preventDefault();
    logout()
      .then(() => {
        setUser(null);
        history.push(`/`);
      })
      .catch(e => { })
  };

  // GET CUSTOMER ADDRESSES BLOCK
  const getCustomerAddresses = () => {
    get_addresses()
      .then(response => {
        if (response?.status === 200) {
          setAddressList(response.data);
        }
        else if (response?.status === 500) {
          // toast.error(translation.notification.http_error);
        }
        else {
          // Mute because customer has no addresses
        }
      })
      .catch(error => {
        // toast.error(translation.notification.http_error);
      });
  };

  useEffect((): any => {
    return getCustomerAddresses()
    // eslint-disable-next-line
  }, []);

  // GET CUSTOMER CONTACTS BLOCK
  const getCustomerContacts = () => {
    get_contacts()
      .then(response => {
        if (response?.status === 200) {
          setContactList(response.data);
        }
        else if (response?.status === 500) {
          // toast.error(translation.notification.http_error);
        }
        else {
          // Mute because customer has no addresses
        }
      })
      .catch(error => {
        // toast.error(translation.notification.http_error);
      });
  };

  useEffect((): any => {
    return getCustomerContacts()
    // eslint-disable-next-line
  }, []);


  // Handle the edit to a value
  const saveEdit = () => {
    const updated = {
      "main_contact": mainContact.current,
      "shipping_address": shippingAddress.current,
    }
    update_user(updated)
      .then(response => {
        if (response?.status === 200) {
          getCustomerInfo();
        }
        else {
          // toast.error(translation.notification.http_error);
        }
      })
      .catch(error => {
        // toast.error(translation.notification.http_error);
      });
  };

  // ADDRESSES
  const handleDefaultAddress = event => {
    event.preventDefault();
    shippingAddress.current = selectedAddress.current;
    saveEdit();
  };

  const handleDeleteAddress = event => {
    event.preventDefault();

    delete_address(selectedAddress.current)
      .then(response => {
        if (response?.status === 200) {
          getCustomerAddresses();
        }
        else if (response?.status === 500) {
          // toast.error(translation.notification.http_error);
        }
        else {
          // Mute because customer has no addresses
        }
      })
      .catch(error => {
        // toast.error(translation.notification.http_error);
      });
  };

  const handleSaveAddress = event => {
    event.preventDefault();

    save_address(newAddress.current)
      .then(response => {
        if (response?.status === 200) {
          setAddAddress(false);
          getCustomerAddresses();
        }
        else {
          // toast.error(translation.notification.http_error);
        }
      })
      .catch(error => {
        // toast.error(translation.notification.http_error);
      });
  };

  const handleCancelAddress = event => {
    event.preventDefault();
    setAddAddress(false);
    //@ts-ignore
    newAddress.current = {};
  };

  // CONTACTS
  const handleDefaultContact = event => {
    event.preventDefault();
    mainContact.current = selectedContact.current;
    saveEdit();
  };

  const handleDeleteContact = event => {
    event.preventDefault();

    delete_contact(selectedContact.current)
      .then(response => {
        if (response?.status === 200) {
          getCustomerContacts();
        }
        else if (response?.status === 500) {
          // toast.error(translation.notification.http_error);
        }
        else {
          // Mute because customer has no addresses
        }
      })
      .catch(error => {
        // toast.error(translation.notification.http_error);
      });
  };

  const handleSaveContact = event => {
    event.preventDefault();
    save_contact(newContact.current)
      .then(response => {
        if (response?.status === 200) {
          setAddContact(false);
          getCustomerContacts();
        }
        else {
          // toast.error(translation.notification.http_error);
        }
      })
      .catch(error => {
        // toast.error(translation.notification.http_error);
      });
  };

  const handleCancelContact = event => {
    event.preventDefault();
    setAddContact(false);
    //@ts-ignore
    newContact.current = {};
  };


  if (!user) {
    return <Redirect to="/login" />;
  };

  return (
    <IcePage>

      {
        userInfo ?

          <Grid container className={classes.profileGrid}>

            <Grid item xs={12} className={classes.profileGridTitle}>
              <Typography className={classes.profileTitle}>
                {userInfo.company_name}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.profileGridSection}>
              <Typography className={classes.profileText}>
                {translation.contacts.default_contact}:
              </Typography><br />
              {
                contactList.length !== 0 &&
                contactList.map((contact: Contact) => {
                  if (contact.id === mainContact.current) {
                    return (
                      <React.Fragment key={contact.id}>
                        <Typography
                          className={classes.profileContact}
                        >
                          {contact.name && <>{contact.name}<br /></>}
                          {contact.telephone && <>{contact.telephone}<br /></>}
                          {contact.email && <>{contact.email}<br /></>}
                        </Typography>
                      </React.Fragment>
                    )
                  }
                  return null;
                })
              }
            </Grid>

            <Grid item xs={12} className={classes.profileGridSection}>
              <Typography className={classes.profileText}>
                {translation.addresses.default}:
              </Typography><br />
              {
                addressList.length !== 0 &&
                addressList.map((address: Address) => {
                  if (address.id === shippingAddress.current) {
                    const fullAddress = addressFormatting(address);
                    return (
                      <React.Fragment key={address.id}>
                        <Typography
                          className={classes.profileAddress}

                        >
                          {fullAddress}
                        </Typography>
                      </React.Fragment>
                    )
                  }
                  return null;
                })
              }
            </Grid>


            <Grid item xs={12} md={6} className={addContact ? classes.profileGridSectionNew : classes.profileGridSection}>
              <Typography className={classes.profileText}>
                {translation.contacts.contacts}
              </Typography>
              <Grid item xs={12} className={classes.profileGridRow} style={addContact ? { flexDirection: 'column' } : {}}>
                {
                  addContact
                    ? (
                      <>
                        <ContactCreate
                          childRef={newContact}
                          setAddContact={setAddContact}
                        />
                        <Grid item xs={12} md={6}>
                          <Button
                            className={classes.actionButton}
                            onClick={(event) => handleSaveContact(event)}
                          >
                            {translation.action.save}
                          </Button>
                          <Button
                            className={classes.specialButton}
                            onClick={(event) => handleCancelContact(event)}>

                            {translation.action.cancel}
                          </Button>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <ContactPicker
                          contactList={contactList}
                          childRef={selectedContact}
                          setAddContact={setAddContact}
                          mainContact={userInfo?.main_contact}
                        />
                        <IconButton
                          className={classes.iconButtonBookmark}
                          onClick={(event) => handleDefaultContact(event)}
                        >
                          <BookmarkIcon />
                        </IconButton>
                        <IconButton
                          className={classes.iconButtonDelete}
                          onClick={(event) => handleDeleteContact(event)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )
                }
              </Grid>
            </Grid>


            <Grid item xs={12} md={6} className={addAddress ? classes.profileGridSectionNew : classes.profileGridSection}>
              <Typography className={classes.profileText}>
                {translation.addresses.addresses}
              </Typography>
              <Grid item xs={12} className={classes.profileGridRow} style={addAddress ? { flexDirection: 'column' } : {}}>
                {
                  addAddress
                    ? (
                      <>
                        <AddressCreate
                          childRef={newAddress} // child gives back a Address object
                          setAddAddress={setAddAddress} // child can disable its state when finished
                        />
                        <Grid item xs={12} md={6}>
                          <Button
                            className={classes.actionButton}
                            onClick={(event) => handleSaveAddress(event)}
                          >
                            {translation.action.save}
                          </Button>
                          <Button
                            className={classes.specialButton}
                            onClick={(event) => handleCancelAddress(event)}>
                            {translation.action.cancel}
                          </Button>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <AddressPicker
                          addressList={addressList}
                          childRef={selectedAddress}
                          shippingAddress={userInfo?.shipping_address}
                          setAddAddress={setAddAddress}
                        />
                        <IconButton
                          className={classes.iconButtonBookmark}
                          onClick={(event) => handleDefaultAddress(event)}
                        >
                          <BookmarkIcon />
                        </IconButton>
                        <IconButton
                          className={classes.iconButtonDelete}
                          onClick={(event) => handleDeleteAddress(event)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )
                }
              </Grid>
            </Grid>


            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                className={`${classes.specialButton} ${classes.logoutButton}`}
                onClick={(event) => logOut(event)}
              >
                {translation.action.logout}
              </Button>
            </Grid>

          </Grid >
          :
          <Typography>{translation.notification.loading}</Typography>
      }

    </IcePage >
  );


};

export default Profile;





