import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Product } from '../../types/types';
import { get_products } from '../Api';
import { priceFormat } from '../Components/utils';
import useTranslation from '../i18n/LanguageHook';
import product_403448 from '../Media/_coldbox.png';
import product_10249756 from '../Media/_cubo5x5.png';
import product_371131 from '../Media/_ghiacco_pile.png';
import product_17950078 from '../Media/_ghiacco_secco_16mm.png';
import product_2495375 from '../Media/_ghiacco_secco_3mm.png';
import { default as product_12044734, default as product_371130 } from '../Media/_gourmet.png';
import product_590332 from '../Media/_hoshizaki.png';
import product_2112858 from '../Media/_iceball.png';
import product_2213839 from '../Media/_ice_bullion.png';
// import filler from '../Media/_white.png';
import filler_logo from '../Media/_logo_only_gray.png';


const imageHash = {
  "product_403448": product_403448,
  "product_371131": product_371131,
  "product_17950078": product_17950078,
  "product_2495375": product_2495375,
  "product_371130": product_371130,
  "product_12044734": product_12044734,
  "product_590332": product_590332,
  "product_2112858": product_2112858,
  "product_2213839": product_2213839,
  "product_10249756": product_10249756,
}



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileProductGrid: {
      width: '100%',
      maxWidth: '100%',
      display: 'grid',
      gridGap: '0',
      margin: 0,
      padding: theme.spacing(0, 0.5, 0, 1),
      gridTemplateColumns: '1fr auto auto auto',
      '@media (max-width: 350px)': {
        gridTemplateColumns: '1fr auto auto',
      },
      gridAutoRows: 'auto',
      alignItems: 'center',
      alignContent: 'center',
    },
    mobileProductLine: {
      borderBottom: 'solid 1px rgba(0,0,0, .125)',
    },
    mobileProductGridName: {
      borderBottom: 'solid 1px rgba(0,0,0, .125)',
      height: '100%',
      maxWidth: '100%',
      padding: theme.spacing(1, 0),
    },
    mobileProductText: {
      textAlign: 'left',
      fontSize: 'clamp(0.75rem, 4vw, 1rem)',
      fontWeight: 600,
      color: theme.palette.secondary.dark,
    },
    mobileProductPrice: {
      color: '#999999',
      fontSize: 'clamp(0.7rem, 4vw, 0.9rem)',
    },
    mobileProductGridUnit: {
      textAlign: 'right',
      paddingRight: theme.spacing(1),
      height: '100%',
      borderBottom: 'solid 1px rgba(0,0,0, .125)',
      display: 'grid',
      alignItems: 'center',
    },
    mobileProductUnit: {
      color: '#000000',
      fontSize: 'clamp(0.75rem, 4vw, 1rem)',
      fontWeight: 600,
    },
    mobileProductGridImage: {
      '@media (max-width: 350px)': {
        display: 'none',
      },
      height: '100%',
      borderBottom: 'solid 1px rgba(0,0,0, .125)',
      display: 'grid',
      alignItems: 'center',
    },
    mobileProductImage: {
      objectFit: 'scale-down',
      objectPosition: 'center',
      minWidth: '3rem',
      maxWidth: '3rem',
      minHeight: '3rem',
      maxHeight: '3rem',
      margin: theme.spacing(1),
    },
    mobileProductGridButtons: {
      textAlign: 'center',
      borderBottom: 'solid 1px rgba(0,0,0, .125)',
      height: '100%',
      display: 'grid',
      alignItems: 'center',
      maxWidth: '100%',
    },
    mobileProductButtons: {
      width: '100%',
    },
    mobileProductButtonsLabelGrouped: {
      width: '100%',
      minWidth: 0,
      '&.MuiButtonGroup-groupedContainedHorizontal:not(:last-child).Mui-disabled': {
        border: 0,
      },
      '&:not(:last-child)': {
        border: 0,
      },
    },
    mobileProductButton: {
      border: 0,
      width: '2.125rem',
      height: '2.125rem',
      fontSize: '1.5rem',
      borderRadius: '20em',
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      fontWeight: 800,
      '&:disabled': {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.primary.contrastText,
        border: `solid 1px ${theme.palette.primary.contrastText}`,
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
      "@media (pointer: fine)": {
        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
    productGrid: {
      width: '100%',
      maxWidth: '960px',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, 140px)',
      gridAutoRows: 'auto',
      gridGap: '1rem',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 'auto',
    },
    productGridItem: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      maxWidth: '100%',
    },
    productCard: {
      width: '100%',
      height: '100%',
      borderRadius: 12,
      minWidth: '140px',
      textAlign: 'center',
      // color: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.contrastText,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: 0,
      margin: 0,
    },
    productImage: {
      objectFit: 'scale-down',
      objectPosition: 'center',
      maxHeight: '5rem',
      border: `solid 10px ${theme.palette.common.white}`,
    },
    productTitle: {
      fontSize: 'clamp(0.75rem, 2vw, 1rem)',
      textAlign: 'center',
      padding: 0,
      margin: 0,
    },
    productCardContent: {
      // padding: theme.spacing(2, 1),
      padding: theme.spacing(0),
      // border: 'solid 2px red'
    },
    productText: {
      fontSize: 'clamp(0.75rem, 2vw, 1rem)',
      textAlign: 'center',
      margin: 0,
      color: theme.palette.secondary.dark,
      fontWeight: 600,
    },
    productUnit: {
      color: '#999999',
      fontSize: 'clamp(0.70rem, 1.75vw, 0.95rem)',
    },
    productCardActions: {
      display: 'flex',
      justifyContent: 'space-around',
      // border: 'solid 2px red'
    },
    productButtons: {

    },
    productButtonsLabelGrouped: {
      '&.MuiButtonGroup-groupedContainedHorizontal:not(:last-child).Mui-disabled': {
        border: 0,
      },
      '&:not(:last-child)': {
        border: 0,
      }
    },
    productButton: {
      border: `solid 1px ${theme.palette.primary.contrastText}`,
      borderRadius: '20em',
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      fontWeight: 800,
      '&:disabled': {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.primary.contrastText,
        border: `solid 1px ${theme.palette.primary.contrastText}`,
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
      "@media (pointer: fine)": {
        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
    productGridTotal: {
      marginTop: theme.spacing(3),
      margin: 'auto',
      width: '90%',
      textAlign: 'center',
      padding: theme.spacing(0, 1),
      borderRadius: '20em',
      border: `solid 2px ${theme.palette.primary.main}`,
      color: theme.palette.common.black,
    },
    productTotal: {
      fontSize: 'clamp(0.75rem, 3.5vw, 1.1rem)',
      // textShadow: `1px 1px 2px ${theme.palette.primary.dark}`,
      width: '100%',
      margin: 0,
      padding: theme.spacing(1),
    },
    loadingText: {
      fontSize: 'clamp(0.75rem, 2vw, 1rem)',
    },
  })
);

const ProductList = (props) => {

  const classes = useStyles();
  const translation: any = useTranslation();
  const mobilescreen = useMediaQuery('(max-width:350px)');
  const smartphone = useMediaQuery('(max-width:600px)');

  const [cart, setCart] = useState<Array<Product>>([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    props.childRef.current = cart;
  }, [cart, props.childRef]);

  const getProducts = () => {
    return new Promise((resolve, reject) => {
      get_products()
        .then(response => {
          if (response?.status === 200) {
            setProductList(response.data);
            setCart(response.data.map(product => { return ({ ...product, "quantity": 0 }); }));
          }
          else {
            // toast.error(translation.notification.http_error);
          }
        })
        .catch(error => {
          // toast.error(translation.notification.http_error);
        });
    });
  };

  useEffect((): any => {
    return getProducts()
    // eslint-disable-next-line
  }, []);


  const getIndex = productId => {
    return cart.findIndex((element: Product) => element.id === productId);
  };

  const handleStepQuantity = (event, productId, step) => {
    event.preventDefault();
    const idx = getIndex(productId);
    const value = Number(cart[idx].quantity) + step;
    let newCart = [...cart];
    newCart[idx] = { ...newCart[idx], "quantity": value }
    if (typeof (value) === 'number' && value >= 0) {
      setCart(newCart);
    }
  };

  useEffect(() => {
    setCartTotal(cart.reduce((total, item) => {
      return total + item.net_price * item.quantity
    }, 0));
  }, [cart]);


  const CartTotal = () => (
    <Grid container component={Paper} className={classes.productGridTotal}>
      <Typography className={classes.productTotal}>
        <strong>{translation.orders.total}: </strong>
        <strong>{cartTotal.toFixed(2).replace(".", ",")} €</strong>
      </Typography>
    </Grid>
  );


  if (mobilescreen || smartphone) {
    return (
      <>
        <Grid
          className={classes.mobileProductGrid}
          component={Paper}
          elevation={0}
        >
          {
            productList.length !== 0 &&
            productList.map((product: Product) => {
              return (
                <React.Fragment key={product.id}>
                  <Grid item className={classes.mobileProductGridName}>
                    <Typography className={classes.mobileProductText}>
                      {product.shop_name || product.name}
                    </Typography>
                    <Typography className={classes.mobileProductPrice}>
                      €
                      {
                        priceFormat(product.net_price)
                      }
                    </Typography>
                  </Grid>
                  <Grid item className={classes.mobileProductGridImage}>
                    {
                      (() => {
                        if (imageHash["product_" + product.fatture_id]) {
                          return (
                            <img
                              src={imageHash["product_" + product.fatture_id]}
                              alt={product.shop_name || product.name}
                              className={classes.mobileProductImage}
                            />
                          )
                        }
                        return <img src={filler_logo} alt={product.shop_name || product.name} className={classes.mobileProductImage} />
                      })()
                    }
                  </Grid>
                  <Grid item className={classes.mobileProductGridUnit}>
                    <Typography className={classes.mobileProductUnit}>
                      {product.unit.toLowerCase()}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.mobileProductGridButtons}>
                    <ButtonGroup
                      size="small"
                      variant="contained"
                      disableElevation
                      aria-label="outlined primary button group"
                      className={classes.mobileProductButtons}
                      classes={{
                        grouped: classes.mobileProductButtonsLabelGrouped,
                      }}
                    >
                      <Button
                        className={classes.mobileProductButton}
                        onClick={(event) => handleStepQuantity(event, product.id, -product.step)}
                      >-</Button>
                      <Button disabled className={classes.mobileProductButton}>
                        {cart[getIndex(product.id)] ? cart[getIndex(product.id)].quantity : ""}
                      </Button>
                      <Button
                        className={classes.mobileProductButton}
                        onClick={(event) => handleStepQuantity(event, product.id, product.step)}
                      >+</Button>
                    </ButtonGroup>
                  </Grid>
                </React.Fragment>
              );
            })
          }
        </Grid>

        <CartTotal />

      </>
    )
  }

  return (
    <>
      <Grid container className={classes.productGrid}>
        {
          productList.length !== 0 &&
          productList.map((product: Product) => {
            return (
              <React.Fragment key={product.id}>
                <Grid item className={classes.productGridItem}>
                  <Card raised={true} className={classes.productCard}>


                    {
                      (() => {
                        if (imageHash["product_" + product.fatture_id]) {
                          return (
                            <CardMedia
                              component="img"
                              src={imageHash["product_" + product.fatture_id]}
                              className={classes.productImage}
                            />
                          )
                        }
                        return (
                          <CardMedia
                            component="img"
                            src={filler_logo}
                            className={classes.productImage}
                          />
                        )
                      })()
                    }



                    <CardContent className={classes.productCardContent}>
                      <Typography className={classes.productText}>
                        {product.shop_name || product.name}
                      </Typography>
                      <Typography className={classes.productUnit}>
                        {
                          priceFormat(product.net_price)
                        } €&nbsp;
                              {product.unit !== '' && "/"} {product.unit.toLowerCase()}
                      </Typography>

                    </CardContent>
                    {/* <Divider variant="middle" /> */}
                    <CardActions className={classes.productCardActions}>
                      <ButtonGroup
                        size="small"
                        variant="contained"
                        disableElevation={true}
                        className={classes.productButtons}
                        style={{ border: 0, margin: 0, padding: 0 }}
                        classes={{
                          grouped: classes.productButtonsLabelGrouped,
                        }}
                      >
                        <Button
                          className={classes.productButton}
                          onClick={(event) => handleStepQuantity(event, product.id, -product.step)}
                        >-</Button>
                        <Button disabled className={classes.productButton}>
                          {cart[getIndex(product.id)] ? cart[getIndex(product.id)].quantity : ""}
                        </Button>
                        <Button
                          className={classes.productButton}
                          onClick={(event) => handleStepQuantity(event, product.id, product.step)}
                        >+</Button>
                      </ButtonGroup>
                    </CardActions>
                  </Card>
                </Grid>
              </React.Fragment>
            );
          })
        }
      </Grid>

      <CartTotal />

    </>
  );
};


export default ProductList;
