const englishMessages = {
  notification: {
    cart_not_valid: 'Please check the cart',
    delivery_date_error: 'The date cannot be set in the past',
    http_error: 'Communication error with the server',
    loading: 'Loading',
    order_deleted: "Order successfully deleted",
    order_thank_you: "Order confirmed, thank you!",
    selected_date_not_valid: 'The selected date is not valid',
    sign_in_error: 'Authentication failed, please try again.',
    timeslot_not_valid: 'The selected Timeslot is not valid',
    please_wait: 'Please wait',
    contact_not_selected: 'Please select a contact for the delivery',
  },
  action: {
    save: 'Save',
    cancel: 'Cancel',
    sign_in: 'Sign in',
    calculate_costs: 'Calculate shipping costs',
    confirm_order: 'Confirm Order',
    cancel_order: 'Cancel Order',
    logout: 'Sign out',
  },
  login: {
    username: 'Username',
    password: 'Password',
    my_account: 'My account',
    remember: "Remember me",
  },
  addresses: {
    delivery_address: 'Delivery address',
    address: 'Address',
    addresses: 'Addresses',
    default: 'Default address',
    save_address: 'Save address',
    delivery_instructions: 'Insert delivery instructions',
  },
  contacts: {
    telephone: "Telephone",
    name: "Contact name",
    default_contact: 'Main contact',
    save_contact: 'Save contact',
    contacts: 'Contacts',
  },
  orders: {
    products: 'Products',
    cancelled: 'Cancelled',
    click_tax_details: "Click to open",
    confirmed: 'Confirmed',
    delivered: 'Delivered',
    delivery_date: 'Delivery date',
    draft: 'Draft',
    due_date: 'Due date',
    history: "Order history",
    name: 'Order',
    new_order: 'New Order',
    no_orders: 'No orders yet',
    order_confirmation_document: 'Order confirmation document',
    order_date: "Order date",
    order_summary: "Order summary",
    pending: 'Pending',
    ref: 'Ref',
    status: 'Status',
    tax_amount: "VAT",
    tax_details: "Tax details",
    tax_percentage: "Tax rate",
    taxable_amount: 'Taxable amount',
    timeslot: 'Delivery timeslot',
    total: 'Total',
    total_amount: 'Total amount',
  }
}

export default englishMessages;