import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Timeslot } from '../../types/types';
import { get_timeslots } from '../Api';
import useTranslation from '../i18n/LanguageHook';
import { timezoneStamp } from './utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orderConfirmGridAddress: {
      padding: theme.spacing(1, 0, 0),
      minWidth: '100%',
      maxWidth: '100%',
      width: '100%',
    },
    orderConfirmAddress: {
      margin: theme.spacing(1, 0),
      minWidth: '100%',
      maxWidth: '100%',
      width: '100%',
      minHeight: '50px',
    },
  })
);


const TimeslotPicker = ({ childRef, deliveryDate }) => {

  const classes = useStyles();
  const translation: any = useTranslation();
  const [selectedTimeslot, setSelectedTimeslot] = useState("");
  const [timeslots, setTimeslots] = useState([]);


  const getTimeslots = deliveryDate => {

    const delivery_date = timezoneStamp(deliveryDate);

    get_timeslots(delivery_date)
      .then(response => {
        if (response?.status === 200) {
          setTimeslots(response.data);
        }
        else {
          // toast.error(translation.notification.http_error);
        }
      })
      .catch(error => {
        // toast.error(translation.notification.http_error);
      });
  };

  const handleSelect = event => {
    event.preventDefault();
    setSelectedTimeslot(event.target.value);
  }

  useEffect((): any => {
    return getTimeslots(deliveryDate || new Date())
    // eslint-disable-next-line
  }, [deliveryDate]);

  useEffect(() => {
    childRef.current = selectedTimeslot;
  }, [selectedTimeslot, childRef]);

  return (
    (timeslots && timeslots.length !== 0)
      ? (
        <FormControl style={{ minWidth: '100%', maxWidth: '100%' }}>
          {/* <InputLabel id="demo-simple-select-label">Timeslot</InputLabel> */}
          <TextField
            select
            className={classes.orderConfirmAddress}
            label={translation.orders.timeslot}
            id="demo-simple-select-helper"
            value={selectedTimeslot}
            variant="outlined"
            onChange={(event: any) => handleSelect(event)}
            style={{ overflowWrap: 'anywhere' }}
          >
            <span></span>
            {
              timeslots.length !== 0 &&
              timeslots.map((timeslot: Timeslot) => {
                return (
                  <MenuItem key={timeslot.id} value={timeslot.id}>
                    {timeslot.name}
                    {/* [{timeslot.start_time.slice(0, 5)} - {timeslot.end_time.slice(0, 5)}]
                    &nbsp;
                    {timeslot.description} */}
                  </MenuItem>
                );
              })
            }
          </TextField>
        </FormControl>
      ) : <span>{translation.notification.loading}</span>
  );
};


export default TimeslotPicker;