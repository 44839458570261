import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import * as React from 'react';
import { useState } from 'react';
import { priceFormat, readDateString, readDateTimeString } from '../Components/utils';
import useTranslation from "../i18n/LanguageHook";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 0,
      padding: 0,
      margin: 0,
    },
    tableHead: {
      border: 'solid 1px black',
      padding: theme.spacing(1, 2),
      fontSize: 'clamp(0.75rem, 3vw, 1rem)',
      backgroundColor: '#cccccc',
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0.5, 1),
      },
    },
    tableCell: {
      border: 'solid 1px black',
      padding: theme.spacing(1, 2),
      fontSize: 'clamp(0.75rem, 3vw, 1rem)',
      textTransform: 'capitalize',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1, 1),
      },
    },
    button: {
      alignSelf: 'center',
      margin: theme.spacing(1),
      fontWeight: 600,
      borderRadius: '20em',
    },
    orderCancelButton: {
      alignSelf: 'center',
      borderRadius: '20em',
      margin: theme.spacing(3, 1, 3, 1),
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
      transition: '0.3s',
      '&:hover': {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
      },
      "@media (pointer: fine)": {
        '&:hover': {
          backgroundColor: theme.palette.error.dark,
          color: theme.palette.error.contrastText,
        },
      },
      fontWeight: 600,
    },
    invoice: {
      maxWidth: 'min(900px, 100%)',
    },
    invoiceSection: {
      // border: `solid 1px ${theme.palette.primary.dark}`,
      borderLeft: `solid 5px ${theme.palette.primary.dark}`,
      // borderRadius: 5,
      alignSelf: 'start',
      textAlign: 'left',
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
      height: '100%',
    },
    invoiceSectionTitle: {
      // fontSize: 'clamp(0.75rem, 4vw, 1.25rem)',
      fontWeight: 600,
      // backgroundColor: theme.palette.primary.main,
      // borderRadius: '20em',
      // color: theme.palette.primary.contrastText,
      // padding: theme.spacing(0, 1)
    },
    invoiceSectionLink: {
      fontWeight: 600,
      fontSize: '1rem',
      padding: theme.spacing(2, 0, 0, 0),
      display: 'flex',
      alignItems: 'center',
    },
    invoiceSectionText: {
      // fontSize: 'clamp(0.75rem, 2vw, 1rem)',
      display: 'inline-block',
      padding: theme.spacing(0, 2),
    },
    invoiceTaxDetailsClickable: {
      color: theme.palette.secondary.main,
      fontStyle: 'italic',
      fontWeight: 500,
      // fontSize: 'clamp(0.75rem, 2vw, 1rem)',
      display: 'inline-block',
      // margin: 'auto',
      padding: theme.spacing(0, 2),
      '& > span': {
        color: theme.palette.error.main,
      },
      transition: '0.3s',
      '&:hover': {
        color: theme.palette.secondary.main,
        '& > span': {
          color: theme.palette.error.main,
        },
      },
      "@media (pointer: fine)": {
        '&:hover': {
          color: theme.palette.secondary.dark,
          '& > span': {
            color: theme.palette.error.dark,
          },
        },
      },
    },
  }),
);


/*
 * Main Component
 */


const OrderDetailGrid = ({ order, cancelOrder, cancelling = false, singleColumn = false }) => {

  const translation: any = useTranslation();
  const mobilescreen = useMediaQuery('(max-width:350px)');
  const classes = useStyles();
  const [showTax, setShowTax] = useState(false);


  return (
    order ? (
      <>
        <Grid container spacing={1} className={classes.invoice}>

          {/* ORDER INFORMATION */}

          <Grid item md={singleColumn ? 12 : 6} xs={12} className={classes.invoiceSection}>
            <Typography className={classes.invoiceSectionTitle}>
              {translation.orders.name}
            </Typography>
            <Typography className={classes.invoiceSectionText}>
              {translation.orders.ref}: {order.id}
              <br />
              {translation.orders.order_date}: {readDateTimeString(order.created_at)}
              <br />
              {translation.orders.delivery_date}: &nbsp;
              {readDateString(order.delivery_date)} &nbsp;
              {order.delivery_time}
              <br />
              {translation.orders.due_date}: &nbsp;
              {readDateString(order.due_date)}
            </Typography>
          </Grid>

          {/* CUSTOMER INFORMATION */}

          <Grid item md={singleColumn ? 12 : 6} xs={12} className={classes.invoiceSection}>
            <Typography className={classes.invoiceSectionTitle}>
              {translation.addresses.address}
            </Typography>
            <Typography className={classes.invoiceSectionText}>
              {
                (() => {
                  try {
                    const address = JSON.parse(order.shipping_address);
                    const fullAddress = [
                      address.street,
                      address.zipcode,
                      address.province,
                      address.city
                    ].filter(Boolean).join(", ");
                    return fullAddress;
                  } catch (e) {
                    return null;
                  }
                })()
              }
            </Typography>
            <br />
            <Typography className={classes.invoiceSectionText}>
              {order.contact_name}
            </Typography>
            <br />
            <Typography className={classes.invoiceSectionText}>
              {order.telephone}
            </Typography>
            <br />
            <Typography className={classes.invoiceSectionText}>
              {order.delivery_instructions ? order.delivery_instructions : null}
            </Typography>
          </Grid>

          {/* PRODUCT LIST */}

          <Grid item xs={12} className={classes.invoiceSection}>

            <Typography className={classes.invoiceSectionTitle}>
              {translation.orders.products}
            </Typography>


            <ul className={classes.invoiceSectionText}>
              {
                order?.basket && JSON.parse(order?.basket!).map(item => {
                  return (
                    <li key={item.id}>
                      <Typography>
                        {item.quantity}x {item.shop_name || item.name}
                    &nbsp; - {
                          priceFormat((item.net_price * (1 + order.tax_percentage / 100)))} {order.currency} PU - {((item.net_price * (1 + order.tax_percentage / 100)) * item.quantity).toFixed(2).replace(".", ",")}  {order.currency}
                      </Typography>
                    </li>
                  );
                })
              }
            </ul>

          </Grid>

          {/* ORDER AMOUNT TO PAY */}

          <Grid item md={singleColumn ? 12 : 6} xs={12} className={classes.invoiceSection}>

            <Grid item xs={12}>

              <Typography
                className={classes.invoiceSectionTitle}
                style={{ fontWeight: 600 }}
              >
                {translation.orders.total_amount}: &nbsp;
                {order.total_amount.toFixed(2).replace(".", ",")} {order.currency}
              </Typography>

              {/* TAX DETAILS */}

              {
                showTax ? (
                  <>
                    <Typography
                      className={classes.invoiceSectionText}
                    >
                      {translation.orders.taxable_amount}: &nbsp;
                      {order.taxable_amount.toFixed(2).replace(".", ",")} {order.currency}
                    </Typography>

                    <br />

                    <Typography
                      className={classes.invoiceSectionText}
                    >
                      {translation.orders.tax_percentage}: &nbsp;
                      {Number(order.tax_percentage).toFixed(2).replace(".", ",")}%
                    </Typography>

                    <br />

                    <Typography
                      className={classes.invoiceSectionText}
                    >
                      {translation.orders.tax_amount}: &nbsp;
                      {order.tax_amount.toFixed(2).replace(".", ",")} {order.currency}
                    </Typography>
                  </>
                )
                  :
                  <Typography
                    className={classes.invoiceTaxDetailsClickable}
                    onClick={() => setShowTax(!showTax)}
                  >
                    {translation.orders.tax_details} &nbsp;
                    <span>({translation.orders.click_tax_details})</span>
                  </Typography>
              }


              {
                (
                  order.order_confirmation_token
                  && order.show_fatture_doc
                ) &&
                <Link
                  href={`https://compute.fattureincloud.it/doc/${order.order_confirmation_token}.pdf`}
                  className={classes.invoiceSectionLink}
                  target="_blank"
                >
                  <DescriptionIcon />
                  {translation.orders.order_confirmation_document}
                </Link>
              }


            </Grid>

          </Grid>
          {
            (
              order?.status! === 'pending'
              // || order?.status! === 'draft'
            ) &&
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                startIcon={<DeleteIcon />}
                size={mobilescreen ? "small" : "large"}
                disabled={cancelling}
                className={classes.orderCancelButton}
                onClick={(event) => cancelOrder(event, order?.id)}
              >
                {
                  cancelling
                    ? <>{translation.notification.please_wait}</>
                    : <>{translation.action.cancel_order}</>
                }
              </Button>
            </Grid>
          }

        </Grid>

      </>) : <span>{translation.notification.loading}...</span>
  )
};


export default OrderDetailGrid;