import jwt_decode from 'jwt-decode';
import { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { logout, refresh } from './Api';

export const UserContext: any = createContext(null);

export const useUserContext = () => useContext(UserContext);

export const UserWrapper = ({ children }: any) => {
  const [user, setUser]: any | null = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState({});
  const history = useHistory();

  const refreshToken = () => {
    refresh()
      .then(response => {
        if (response?.status === 200) {
          const access_token = response.data['access_token'];
          const decoded: any = jwt_decode(access_token);
          const JSONdecoded = JSON.parse(decoded.sub);
          if (!JSONdecoded.company_name) {
            logout()
              .then(() => {
                setUser(null);
                history.push(`/`);
              })
              .catch(e => { })
          }
          setToken(access_token);
          setUser({
            username: JSONdecoded.username,
            company_name: JSONdecoded.company_name,
          });
        }
        else {
          // toast.error(translation.notification.http_error);
        }
      })
      .catch(error => {
        // toast.error(translation.notification.http_error);
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          refreshToken();
        }, (300 * 1000) - 500);
      });
  };

  useEffect(() => {
    refreshToken();
    // eslint-disable-next-line
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, token, setToken }}>
      { loading ? null : children}
    </UserContext.Provider >
  );
};

export default UserWrapper;