const italianMessages = {
  notification: {
    cart_not_valid: 'Si prega di controllare il carrello',
    delivery_date_error: 'La data non dovrebbe essere prima della data minima',
    http_error: 'Errore di comunicazione con il server dati',
    loading: 'Caricamento in corso',
    order_deleted: "Ordine cancellato con successo",
    order_thank_you: "Ordine confermato, grazie!",
    selected_date_not_valid: 'La data selezionata non è valida',
    sign_in_error: 'Autenticazione fallita, riprovare.',
    timeslot_not_valid: 'La fascia oraria selezionata non è valida',
    please_wait: 'Attendere prego',
    contact_not_selected: 'Si prega di selezionare un contatto per la consegna',
  },
  action: {
    save: 'Salva',
    cancel: 'Annulla',
    sign_in: 'Accesso',
    calculate_costs: 'Calcolo costi di consegna',
    confirm_order: 'Conferma Ordine',
    cancel_order: 'Annulla Ordine',
    logout: 'Disconnessione',
  },
  login: {
    username: 'Nome utente',
    password: 'Password',
    my_account: 'Il mio account',
    remember: "Ricordati di me",
  },
  addresses: {
    delivery_address: 'Indirizzo di consegna',
    addresses: 'Indirizzi',
    default: 'Indirizzo predefinito',
    save_address: 'Salva indirizzo',
    address: 'Indirizzo',
    delivery_instructions: 'Inserire note per il corriere',
  },
  contacts: {
    telephone: "Telefono referente in loco",
    name: "Nome referente in loco",
    default_contact: 'Contatto principale',
    save_contact: 'Salva contatto',
    contacts: 'Contatti',
  },
  orders: {
    products: 'Prodotti',
    cancelled: 'Cancellato',
    click_tax_details: "Clicca per aprire",
    confirmed: 'Confermato',
    delivered: 'Consegnato',
    delivery_date: 'Data consegna',
    draft: 'Bozza',
    due_date: 'Prossima scadenza',
    history: "Storico Ordini",
    name: 'Ordine',
    new_order: 'Nuovo Ordine',
    no_orders: 'Nessun ordine ancora',
    order_confirmation_document: 'Documento di conferma',
    order_date: "Data ordine",
    order_summary: "Riassunto dell'ordine",
    pending: 'In attesa',
    ref: 'Rif',
    status: 'Stato',
    tax_amount: "IVA",
    tax_details: "Dettagli importo",
    tax_percentage: "Aliquota IVA",
    taxable_amount: 'Imponibile',
    timeslot: 'Fascia oraria di consegna',
    total: 'Totale',
    total_amount: 'Importo totale',
  }
}

export default italianMessages;