import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import * as React from 'react';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Redirect, useHistory } from 'react-router-dom';
import { Order } from '../../types/types';
import { cancel_order, get_order, get_orders } from '../Api';
import IcePage from '../Components/IcePage';
import OrderDetailGrid from '../Components/OrderDetailGrid';
import { readDateString } from '../Components/utils';
import useTranslation from "../i18n/LanguageHook";
import { useUserContext } from '../UserContext';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    page: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(4),
      },
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: 'max(800px, 70%)',
      borderRadius: '5%',
    },
    table: {
      minWidth: 0,
      padding: 0,
      margin: 0,
    },
    tableHead: {
      border: 'solid 1px black',
      padding: theme.spacing(1, 2),
      fontSize: 'clamp(0.75rem, 3vw, 1rem)',
      backgroundColor: '#cccccc',
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0.5, 1),
      },
    },
    tableCell: {
      border: 'solid 1px black',
      padding: theme.spacing(1, 2),
      fontSize: 'clamp(0.75rem, 3vw, 1rem)',
      textTransform: 'capitalize',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1, 1),
      },
    },
    text_accordion_details: {
      // fontSize: 'clamp(0.75rem, 3vw, 1rem)',
    },
    accordionHeader: {
      marginBottom: theme.spacing(2),
    },
    accordionSummary: {
      margin: 0,
      marginLeft: 6,
      width: '35%',
      border: '0px',
      padding: theme.spacing(0),
      // fontSize: 'clamp(0.75rem, 3vw, 1rem)',
      fontWeight: 600,
      textAlign: 'center',
      color: theme.palette.secondary.dark,
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0),
      }
    },
    accordion_details: {
      margin: 0,
      display: 'flex',
      width: '30%',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      border: 'solid 1px black',
      padding: theme.spacing(1, 2),
      // fontSize: 'clamp(0.75rem, 3vw, 1rem)',
      textTransform: 'capitalize',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1, 1),
      },
    },
    button: {
      alignSelf: 'center',
      margin: theme.spacing(1),
      fontWeight: 600,
      borderRadius: '20em',
    },
    orderCancelButton: {
      alignSelf: 'center',
      borderRadius: '20em',
      margin: theme.spacing(3, 1, 3, 1),
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
      transition: '0.3s',
      '&:hover': {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
      },
      "@media (pointer: fine)": {
        '&:hover': {
          backgroundColor: theme.palette.error.dark,
          color: theme.palette.error.contrastText,
        },
      },
      fontWeight: 600,
    },
    invoice: {
      maxWidth: 'min(900px, 100%)',
    },
    invoiceSection: {
      // border: `solid 1px ${theme.palette.primary.dark}`,
      borderLeft: `solid 5px ${theme.palette.primary.dark}`,
      // borderRadius: 5,
      alignSelf: 'start',
      textAlign: 'left',
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
      height: '100%',
    },
    invoiceSectionTitle: {
      // fontSize: 'clamp(0.75rem, 4vw, 1.25rem)',
      fontWeight: 600,
      // backgroundColor: theme.palette.primary.main,
      // borderRadius: '20em',
      // color: theme.palette.primary.contrastText,
      // padding: theme.spacing(0, 1)
    },
    invoiceSectionText: {
      // fontSize: 'clamp(0.75rem, 2vw, 1rem)',
      display: 'inline-block',
      padding: theme.spacing(0, 2),
    },
    invoiceTaxDetailsClickable: {
      color: theme.palette.secondary.main,
      fontStyle: 'italic',
      fontWeight: 500,
      // fontSize: 'clamp(0.75rem, 2vw, 1rem)',
      display: 'inline-block',
      // margin: 'auto',
      padding: theme.spacing(0, 2),
      '& > span': {
        color: theme.palette.error.main,
      },
      transition: '0.3s',
      '&:hover': {
        color: theme.palette.secondary.main,
        '& > span': {
          color: theme.palette.error.main,
        },
      },
      "@media (pointer: fine)": {
        '&:hover': {
          color: theme.palette.secondary.dark,
          '& > span': {
            color: theme.palette.error.dark,
          },
        },
      },
    },
    productTitle: {
      margin: 0,
      padding: 0,
      fontWeight: 400,
      color: theme.palette.primary.contrastText,
      // fontSize: 'clamp(0.75rem, 4vw, 1.25rem)',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
        textAlign: 'left',
      },
    },
    spanDraft: {
      borderRadius: 12,
      boxShadow: `0px 0px 0px 8px #e0e0e0`,
      color: theme.palette.common.black,
      backgroundColor: "#e0e0e0",
      fontWeight: 600,
    },
    spanPending: {
      borderRadius: 12,
      boxShadow: `0px 0px 0px 8px ${theme.palette.warning.main}`,
      color: theme.palette.warning.contrastText,
      backgroundColor: theme.palette.warning.main,
      fontWeight: 600,
    },
    spanConfirmed: {
      borderRadius: 12,
      boxShadow: `0px 0px 0px 8px ${theme.palette.primary.main}`,
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      fontWeight: 600,
    },
    spanDelivered: {
      borderRadius: 12,
      boxShadow: `0px 0px 0px 8px ${theme.palette.success.main}`,
      color: theme.palette.success.contrastText,
      backgroundColor: theme.palette.success.main,
      fontWeight: 600,
    },
    spanCancelled: {
      borderRadius: 12,
      boxShadow: `0px 0px 0px 8px ${theme.palette.error.main}`,
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
      fontWeight: 600,
    },
    invoiceSectionBanner: {
      padding: 10,
      textAlign: 'center',
      borderRadius: 12,
      fontWeight: 400,
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      fontSize: 'clamp(0.75rem, 4vw, 1.25rem)',
    },
    productText: {
      margin: 0,
      padding: 0,
    },
    titleHover: {
      "@media (pointer: fine)": {
        '&:hover': {
          color: theme.palette.secondary.main,
          transition: '0.3s',
          cursor: 'pointer',
        },
        '&:hover > div > table > tbody > tr > td': {
          color: `${theme.palette.common.white} !important`,
        }
      },
    },
    titleIcon: {
      position: 'relative',
      top: '5px',
    }
  }),
);

const Accordion = withStyles((theme) => ({
  root: {
    width: 'min(100vw, 900px)',
    borderRadius: 12,
    boxShadow: 'none',
    border: 0,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    '&:after': {
      // borderBottom: '1px solid rgba(0, 0, 0, .125)',
      display: 'block',
      margin: '0 auto',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      content: '""',
      width: '90%',
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    borderRadius: 12,
    boxShadow: 'none',
    marginLeft: 0,
    marginRight: 0,
    padding: 0,
    minHeight: 56,
    '&$expanded': {
      backgroundColor: theme.palette.primary.main,
      minHeight: 56,
      '& > div > table > tbody > tr > td': {
        color: `${theme.palette.common.white} !important`,
      },
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      minHeight: 0,
      '&$expanded': {
        minHeight: 0,
      },
    },
    transition: '0.3s',
    "@media (pointer: fine)": {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      '&:hover > div > table > tbody > tr > td': {
        color: `${theme.palette.common.white} !important`,
      }
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);


const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
  },
}))(MuiAccordionDetails);

/*
 * Main Component
 */

const OrderHistory = (props) => {

  const translation: any = useTranslation();
  // const mobilescreen = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
  const mobilescreen = useMediaQuery('(max-width:350px)');
  const { user }: any = useUserContext();
  const history = useHistory();
  const [orderList, setOrderList] = useState([]);
  const classes = useStyles();
  // const [showTax, setShowTax] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [sortBy, setSortBy] = useState("delivery_date");
  const [sortDir, setSortDir] = useState("DESC");
  const [checkedGreen, setCheckedGreen] = useState(true);
  const [checkedBlue, setCheckedBlue] = useState(true);
  const [checkedYellow, setCheckedYellow] = useState(true);
  const [checkedRed, setCheckedRed] = useState(true);

  const getOrders = () => {
    return new Promise((resolve, reject) => {
      const filterBy: Array<string> = [];
      if (checkedYellow) { filterBy.push("pending"); }
      if (checkedBlue) { filterBy.push("confirmed"); }
      if (checkedGreen) { filterBy.push("delivered"); }
      if (checkedRed) { filterBy.push("cancelled"); }
      const params = {
        sort_by: sortBy,
        sort_direction: sortDir,
        filter_by: filterBy,
      }
      get_orders(params)
        .then(response => {
          if (response?.status === 200) {
            setOrderList(response.data);
          }
          else {
            // toast.error(translation.notification.http_error);
          }
        })
        .catch(error => {
          // toast.error(translation.notification.http_error);
        });
    });
  };

  useEffect((): any => {
    return getOrders()
    // eslint-disable-next-line
  }, [sortBy, sortDir, checkedYellow, checkedBlue, checkedGreen, checkedRed]);

  const [order, setOrder] = useState<Order | null>(null);

  const getOrder = (orderId: string) => {
    return new Promise((resolve, reject) => {
      get_order(orderId)
        .then(response => {
          if (response?.status === 200) {
            setOrder(response.data);
          }
          else {
            // toast.error(translation.notification.http_error);
          }
        })
        .catch(error => {
          // toast.error(translation.notification.http_error);
        });
    });
  };

  const cancelOrder = (event, orderId) => {
    event.preventDefault();
    setCancelling(true);
    cancel_order(orderId)
      .then(response => {
        if (response?.status === 200) {
          toast.success(translation.notification.order_deleted);
          getOrders()
            .then(() => {
              setCancelling(false);
            });
        }
        else {
          // toast.error(translation.notification.http_error);
        }
      })
      .catch(error => {
        // toast.error(translation.notification.http_error);
      })
      .finally(() => {
        setTimeout(() => {
          setCancelling(false);
        }, 1000);
      });
  };

  const [expanded, setExpanded] = React.useState<string | false>(false);
  useEffect(() => {
    if (expanded === false) {
      setOrder(null);
    }
    if (expanded !== false) {
      getOrder(expanded);
    }
    // eslint-disable-next-line
  }, [expanded]);

  const CustomizedAccordions = () => {

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      // setShowTax(false);
    };

    if (orderList.length === 0) { return (<span>{translation.orders.no_orders}</span>) }

    return (
      <>
        <Table className={classes.accordionHeader} aria-label="simple table" size="small">
          <TableBody>
            <TableRow className={classes.accordionSummary}>
              <TableCell
                className={classes.accordionSummary}
              >
                <span
                  className={classes.titleHover}
                  onClick={() => {
                    if (sortBy === "id") {
                      return (
                        sortDir === "ASC"
                          ? setSortDir("DESC")
                          : setSortDir("ASC")
                      )
                    } else { return setSortBy("id") }
                  }}
                >
                  #{translation.orders.ref}
                  {
                    sortBy === "id"
                      ? sortDir === "ASC"
                        ? <ArrowDropUpIcon className={classes.titleIcon} />
                        : <ArrowDropDownIcon className={classes.titleIcon} />
                      : null
                  }
                </span>
              </TableCell>
              <TableCell className={classes.accordionSummary}>
                <span
                  className={classes.titleHover}
                  onClick={() => {
                    if (sortBy === "delivery_date") {
                      return (
                        sortDir === "ASC"
                          ? setSortDir("DESC")
                          : setSortDir("ASC")
                      )
                    } else { return setSortBy("delivery_date") }
                  }}
                >
                  {translation.orders.delivery_date}
                  {
                    sortBy === "delivery_date"
                      ? sortDir === "ASC"
                        ? <ArrowDropUpIcon className={classes.titleIcon} />
                        : <ArrowDropDownIcon className={classes.titleIcon} />
                      : null
                  }
                </span>
              </TableCell>
              <TableCell className={classes.accordionSummary}>
                <span
                  className={classes.titleHover}
                  onClick={() => {
                    if (sortBy === "status") {
                      return (
                        sortDir === "ASC"
                          ? setSortDir("DESC")
                          : setSortDir("ASC")
                      )
                    } else { return setSortBy("status") }
                  }}
                >
                  {translation.orders.status}
                  {
                    sortBy === "status"
                      ? sortDir === "ASC"
                        ? <ArrowDropUpIcon className={classes.titleIcon} />
                        : <ArrowDropDownIcon className={classes.titleIcon} />
                      : null
                  }
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {
          orderList.map((row: Order) => {
            return (
              <Accordion
                square
                expanded={expanded === row.id}
                onChange={handleChange(row.id)}
                key={row.id}
              >
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Table className={classes.table} aria-label="simple table" size="small">
                    <TableBody>
                      <TableRow className={classes.accordionSummary}>
                        <TableCell className={classes.accordionSummary}>
                          #{row.id}
                        </TableCell>
                        <TableCell className={classes.accordionSummary}>
                          {row.delivery_date && readDateString(row.delivery_date)} &nbsp;
                            </TableCell>
                        <TableCell className={classes.accordionSummary}>
                          {
                            row.status === "draft" &&
                            <span className={classes.spanDraft}>
                              {translation.orders.draft}
                            </span>
                          }
                          {
                            row.status === "pending" &&
                            <span className={classes.spanPending}>
                              {translation.orders.pending}
                            </span>
                          }
                          {
                            row.status === "confirmed" &&
                            <span className={classes.spanConfirmed}>
                              {translation.orders.confirmed}
                            </span>
                          }
                          {
                            row.status === "delivered" &&
                            <span className={classes.spanDelivered}>
                              {translation.orders.delivered}
                            </span>
                          }
                          {
                            row.status === "cancelled" &&
                            <span className={classes.spanCancelled}>
                              {translation.orders.cancelled}
                            </span>
                          }
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionSummary>
                <AccordionDetails>
                  {
                    (expanded === row.id && order)
                      ? <OrderDetailGrid
                        order={row}
                        cancelOrder={cancelOrder}
                        cancelling={cancelling}
                      />
                      : <span>{translation.notification.loading}...</span>
                  }
                </AccordionDetails>
              </Accordion>
            );
          })
        }
      </>
    );
  };


  const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props: any) => <Checkbox color="default" {...props} />);

  const BlueCheckbox = withStyles({
    root: {
      color: blue[400],
      '&$checked': {
        color: blue[600],
      },
    },
    checked: {},
  })((props: any) => <Checkbox color="default" {...props} />);

  const YellowCheckbox = withStyles({
    root: {
      color: yellow[400],
      '&$checked': {
        color: yellow[600],
      },
    },
    checked: {},
  })((props: any) => <Checkbox color="default" {...props} />);

  const RedCheckbox = withStyles({
    root: {
      color: red[400],
      '&$checked': {
        color: red[600],
      },
    },
    checked: {},
  })((props: any) => <Checkbox color="default" {...props} />);

  if (!user) {
    return <Redirect to="/login" />;
  };

  return (
    <IcePage>

      <Container
        disableGutters={mobilescreen ? true : false}
        className={classes.page}
      >
        <Grid
          container
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center'
          }}
        >
          <FormControlLabel
            control={
              <YellowCheckbox
                checked={checkedYellow}
                onChange={() => setCheckedYellow(!checkedYellow)}
                name="checkedYellow"
              />
            }
            label={translation.orders.pending}
          />
          <FormControlLabel
            control={
              <BlueCheckbox
                checked={checkedBlue}
                onChange={() => setCheckedBlue(!checkedBlue)}
                name="checkedBlue"
              />
            }
            label={translation.orders.confirmed}
          />
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={checkedGreen}
                onChange={() => setCheckedGreen(!checkedGreen)}
                name="checkedGreen"
              />
            }
            label={translation.orders.delivered}
          />
          <FormControlLabel
            control={
              <RedCheckbox
                checked={checkedRed}
                onChange={() => setCheckedRed(!checkedRed)}
                name="checkedRed"
              />
            }
            label={translation.orders.cancelled}
          />
        </Grid>

        <Box my={2}>
          <CustomizedAccordions />
        </Box>

        <Box my={2}>
          <Button
            variant="contained"
            size={mobilescreen ? "medium" : "large"}
            // fullWidth={mobilescreen ? true : false}
            color="primary"
            className={classes.button}
            onClick={() => history.push('/create')}
          >
            {translation.orders.new_order}
          </Button>
        </Box>

      </Container>
    </IcePage >
  );

};

export default OrderHistory;





