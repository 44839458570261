/* =============================
 Create a global context for Language
================================ */
import React, { useState, useContext } from "react";

interface ContextProps {
  language: string;
  changeLanguage: any;
}

const LanguageContext = React.createContext({} as ContextProps);

export const useLanguageContext = () => useContext(LanguageContext);

export default function LanguageContextProvider({ children }) {
  const [language, changeLanguage] = useState("it");
  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}