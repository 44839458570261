import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import MyAppBar from './MyAppBar';

const useStyles = makeStyles((theme: Theme) => {

  const smallbar = 64;
  const bigbar = 96;

  return (
    createStyles({
      grow: {
        flexGrow: 1,
      },
      paperGround: {
        borderRadius: 50,
        padding: theme.spacing(5, 1, 1, 1),
        top: smallbar,
        [theme.breakpoints.up('sm')]: {
          top: bigbar,
        },
        position: 'relative',
        maxWidth: '960px',
        height: 'max(100vh, 100%)',
      },
      profileGrid: {
      },
      profileGridTitle: {
        borderRadius: 12,
        padding: theme.spacing(1),
      },
      profileTitle: {
        fontSize: 'clamp(1.5rem, 10vw, 3rem)',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
          fontWeight: 400,
        }
      },
      profileGridSection: {
        borderRadius: 12,
        padding: theme.spacing(1),
      },
      profileSectionTitle: {
        fontSize: 'clamp(1.25rem, 4vw, 1.5rem)',
      },
      profileSectionText: {
        fontSize: 'clamp(0.75rem, 3vw, 1rem)',
      },
      saveNewAddress: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
      },
    })
  )
});


const IcePage = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <MyAppBar />
      <Container
        disableGutters={true}
        component={Paper}
        elevation={5}
        className={classes.paperGround}
      >
        {children}
      </Container>
    </>
  );
};

export default IcePage;





