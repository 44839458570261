import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import MuiPhoneNumber from 'material-ui-phone-number';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Contact } from '../../types/types';
import useTranslation from '../i18n/LanguageHook';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orderConfirmContact: {
      minWidth: '100%',
      maxWidth: '100%',
      width: '100%',
      minHeight: '50px',
      margin: theme.spacing(1, 0),
    },
    orderConfirmContactButton: {
      width: '100%',
      margin: theme.spacing(1, 0, 0),
      fontWeight: 600,
      maxWidth: '100%',
      borderRadius: '20em',
    },
    gridContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      }
    },
    cancelContactButton: {
      width: '100%',
      margin: theme.spacing(1, 0, 0),
      fontWeight: 600,
      maxWidth: '100%',
      borderRadius: '20em',
    },
    gridItem: {
      height: '100%',
    },
    specialButton: {
      marginTop: theme.spacing(1),
      fontWeight: 600,
      width: '100%',
      display: 'inline-block',
      borderRadius: '20em',
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
      "@media (pointer: fine)": {
        '&:hover': {
          backgroundColor: theme.palette.error.dark,
        },
      },
    },
    actionButton: {
      marginTop: theme.spacing(1),
      fontWeight: 600,
      width: '50%',
      display: 'inline-block',
      borderRadius: '20em',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      "@media (pointer: fine)": {
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
    orderConfirmGridTelephone: {
      maxWidth: '100%',
      padding: theme.spacing(1, 0),
      gridColumn: '1/2', // 2 columns above 960px
      [theme.breakpoints.between('xs', 'sm')]: { // 1 column between 600 and 960px
        gridColumn: '1/3',
      },
      [theme.breakpoints.between('smartphone', 'xs')]: { // 2 columns between 450 and 600px
        gridColumn: '1/2',
      },
      [theme.breakpoints.between('mobile', 'smartphone')]: { // 1 column between 600 and 960px
        gridColumn: '1/3',
      },
    },
    orderConfirmGridContact: {
      maxWidth: '100%',
      // padding: theme.spacing(1, 0),
      gridColumn: '1/3', // 2 columns above 960px
      [theme.breakpoints.between('xs', 'sm')]: { // 1 column between 600 and 960px
        gridColumn: '1/3',
      },
      [theme.breakpoints.between('smartphone', 'xs')]: { // 2 columns between 450 and 600px
        gridColumn: '2/3',
      },
      [theme.breakpoints.between('mobile', 'smartphone')]: { // 1 column between 600 and 960px
        gridColumn: '1/3',
      },
    },
    invertedIcon: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '20em',
      // color: theme.palette.primary.main,
      fontWeight: 600,
    },
    newContactField: {
      margin: theme.spacing(1, 0),
      width: '100%',
      maxWidth: '100%',
    },
    inputIcon: {
      color: theme.palette.primary.main,
      borderRadius: '20em',
      // color: theme.palette.primary.main,
      fontWeight: 600,
    },
    telephone: {
      width: '100%',
      margin: theme.spacing(1, 0),
    },
    input: {
      fontSize: '1rem',
    },
  })
);

export const ContactPicker = ({ contactList, childRef, setAddContact, mainContact }) => {

  const classes = useStyles();
  const translation: any = useTranslation();
  const [selectedContact, setSelectedContact] = useState<string>(' ');


  useEffect(() => {
    // const idx = contactList.findIndex((element: Contact) => element.id === selectedContact);
    // childRef.current = contactList[idx];
    childRef.current = selectedContact;
  }, [childRef, selectedContact]);

  useEffect(() => {
    if (mainContact) {
      setSelectedContact(mainContact);
    }
  }, [mainContact]);

  return (
    <>
      {
        selectedContact ?
          (
            <>
              <TextField
                select
                label={translation.contacts.name}
                value={selectedContact}
                variant="outlined"
                className={classes.newContactField}
                onChange={(event: any) => setSelectedContact(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" onClick={() => setAddContact(true)}>
                      <IconButton>
                        <AddCircleIcon
                          className={classes.invertedIcon}

                        />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              >
                <span></span>
                {
                  contactList.length !== 0 &&
                  contactList.map((contact: Contact) => {
                    return (
                      <MenuItem
                        key={contact.id}
                        id={contact.id}
                        value={contact.id}
                      >
                        {contact.name} {contact.telephone}
                      </MenuItem>
                    );
                  })
                }
              </TextField>
            </>
          )
          :
          (
            <Typography>
              {translation.notification.loading}
            </Typography>
          )
      }
    </>
  );
};


export const ContactCreate = ({ childRef, setAddContact }) => {

  const classes = useStyles();
  const translation: any = useTranslation();
  const [contact, setContact] = useState<string | undefined>("");
  const [telephone, setTelephone] = useState<string | undefined>("");

  useEffect(() => {
    const newContact = {
      name: contact,
      telephone: telephone?.replace(/\s/g, ''),
    };
    // the new contact object
    childRef.current = newContact;
  }, [childRef, contact, telephone]);

  return (
    <>
      <Grid
        item
        className={classes.orderConfirmGridContact}
      >
        <TextField
          variant="outlined"
          label={translation.contacts.name}
          className={classes.newContactField}
          value={contact}
          onChange={event => setContact(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" onClick={() => setAddContact(false)}>
                <IconButton>
                  <ImportContactsIcon
                    className={classes.inputIcon}

                  />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <MuiPhoneNumber
          label={translation.contacts.telephone}
          variant="outlined"
          className={classes.telephone}
          defaultCountry={'it'}
          onlyCountries={['it']}
          InputProps={{
            classes: {
              input: classes.input,
            }
          }}
          value={telephone}
          onChange={setTelephone}
        />
      </Grid>
    </>
  );
};