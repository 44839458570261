import { Address } from '../../types/types';

export const addressFormatting = (address: Address) => {

    const fullAddress = [
        address.street,
        address.zipcode,
        address.province,
        address.city
    ].filter(Boolean).join(", ");
    return fullAddress;
};

export const googleAddressFormatting = (googleAddress) => {
    const newAddress = {};
    if (googleAddress && googleAddress.length !== 0) {
        const base = googleAddress.value
        const l = base?.terms.length;
        newAddress["street"] = base?.structured_formatting.main_text;
        if (l >= 6) {
            newAddress["zipcode"] = base?.terms[2].value;
        }
        newAddress["city"] = base?.terms[l - 3].value;
        newAddress["province"] = base?.terms[l - 2].value;
        newAddress["country"] = base?.terms[l - 1].value;
        newAddress["place_id"] = base?.place_id;
        return newAddress;
    }
    else { return null }
}

export const readDateTimeString = (dateString: string) => {
    if (!dateString) { return null }
    // return dateString.replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}).*/, '$3-$2-$1 $4:$5');
    return new Date(dateString).toLocaleString();
};

export const readDateString = (dateString: string) => {
    if (!dateString) { return null }
    // return dateString.replace(/(\d{4})-(\d{2})-(\d{2}).*/, '$3-$2-$1');
    return new Date(dateString).toLocaleDateString();
};

export const readTimeString = (dateString: string) => {
    if (!dateString) { return null }
    return dateString.replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}).*/, '$4:$5');
};

export const priceFormat = (price: number) => {
    let formattedPrice = String();
    if (price * 100 % 1) {
        formattedPrice = price.toFixed(3)
        const first_half = formattedPrice.split('.')[0]
        const second_half = formattedPrice.split('.')[1]
        if (second_half[2] === "0") {
            formattedPrice = first_half + '.' + second_half.slice(0, 2)
        }
    } else {
        formattedPrice = price.toFixed(2)
    }
    return formattedPrice.replace(".", ",");
};

export const timezoneStamp = (dateObject: Date) => {
    return new Date(
        dateObject.setDate(dateObject.getDate())
        -
        dateObject.getTimezoneOffset() * 60000
    ).toISOString().slice(0, 10);
};