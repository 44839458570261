import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import * as React from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import useTranslation from "../i18n/LanguageHook";
import icebreaker_logo from '../Media/icebreaker_logo_transparent.png';
import { useUserContext } from '../UserContext';


const useStyles = makeStyles((theme: Theme) => {

  const smallbar = 64;
  const bigbar = 96;

  return (
    createStyles({
      root: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
      grow: {
        flexGrow: 1,
      },
      toolbar: {
        minHeight: smallbar,
        maxHeight: smallbar,
        [theme.breakpoints.up('sm')]: {
          minHeight: bigbar,
          maxHeight: bigbar,
        },
        alignItems: 'flex-start',
        padding: theme.spacing(0)
      },
      logo: {
        maxHeight: 48,
        [theme.breakpoints.up('sm')]: {
          maxHeight: 64,
        },
        borderRadius: '20em',
      },
      menuButton: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        [theme.breakpoints.down('mobile')]: {
          padding: 0,
          margin: 0,
        },
        transform: 'scale(1.5)',
      },
      title: {
        fontSize: 'clamp(1.15rem, 5vw, 2rem)',
        fontWeight: 500,
        marginLeft: -theme.spacing(5),
        alignSelf: 'flex-end',
        textAlign: 'center',
        flexGrow: 1,
      },
      sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'flex',
        },
      },
      sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
          display: 'none',
        },
      },
      curvedCornerTopRight: {
        zIndex: 1000,
        top: smallbar,
        [theme.breakpoints.up('sm')]: {
          top: bigbar,
        },
        right: 0,
        width: '50px',
        height: '50px',
        overflow: 'hidden',
        position: 'fixed',
        '&&&:before': {
          content: '""',
          width: '200%',
          height: '200%',
          position: 'absolute',
          borderRadius: '50%',
          top: 0,
          right: 0,
          boxShadow: `25px -25px 0 0 ${theme.palette.primary.main}`,
        }
      },
      curvedCornerTopLeft: {
        zIndex: 1000,
        top: smallbar,
        [theme.breakpoints.up('sm')]: {
          top: bigbar,
        },
        left: 0,
        width: '50px',
        height: '50px',
        overflow: 'hidden',
        position: 'fixed',
        '&&&:before': {
          content: '""',
          width: '200%',
          height: '200%',
          position: 'absolute',
          borderRadius: '50%',
          top: 0,
          left: 0,
          boxShadow: `-25px -25px 0 0 ${theme.palette.primary.main}`,
        }
      },
      topBanner: {
        backgroundColor: theme.palette.primary.main,
        height: bigbar * 2,
        width: '100%',
        position: 'fixed',
        zIndex: -1,
        top: 0,
        '&&&:before': {
          content: '""',
        },
      }
    })
  )
});

/*
 * Main Component
 */

const MyAppBar = props => {

  const location = useLocation();
  const translation: any = useTranslation();
  const { user }: any = useUserContext();
  const history = useHistory();
  const classes = useStyles();
  const [mainMenuAnchorEl, setMainMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const mobilescreen = useMediaQuery('(max-width:350px)');


  const handleMainMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMainMenuAnchorEl(event.currentTarget);
  };

  const handleMainMenuClose = () => {
    setMainMenuAnchorEl(null);
  };

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props: MenuProps) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

  if (!user) {
    return <Redirect to="/login" />;
  };

  const MyCustomMenu = () => {
    return (
      <StyledMenu
        id="customized-menu"
        anchorEl={mainMenuAnchorEl}
        keepMounted
        open={Boolean(mainMenuAnchorEl)}
        onClose={handleMainMenuClose}
      >
        <StyledMenuItem onClick={() => history.push(`/orders`)} >
          <ListItemText primary={translation.orders.history} />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => history.push(`/create`)}>
          <ListItemText primary={translation.orders.new_order} />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => history.push(`/profile`)}>
          <ListItemText primary={translation.login.my_account} />
        </StyledMenuItem>
        {/* <StyledMenuItem onClick={logOut}>
          <ListItemText primary={translation.action.logout} />
        </StyledMenuItem> */}
      </StyledMenu>
    );
  };

  return (
    <>

      <AppBar
        position="fixed"
        elevation={0}
      // style={{ borderRadius: '20em' }}
      >
        <Toolbar
          disableGutters={mobilescreen ? true : false}
          className={classes.toolbar}
        >

          <img src={icebreaker_logo} className={classes.logo} alt="Icebreaker logo" />

          {/* <div className={classes.grow} /> */}
          <Typography className={classes.title} variant="h6" noWrap>
            {user.company_name} <br />
            {location.pathname === '/profile' && translation.login.my_account}
            {location.pathname === '/orders' && translation.orders.history}
            {location.pathname === '/create' && translation.orders.new_order}
          </Typography>

          {/* <div className={classes.grow} /> */}

          <div className={classes.sectionDesktop}>
            {/* <div>
              <LanguageHandler />
            </div> */}
            <IconButton
              edge="end"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={handleMainMenuOpen}
            >
              <MenuIcon />
            </IconButton>
          </div>

          <div className={classes.sectionMobile}>
            <IconButton
              edge="end"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={handleMainMenuOpen}
            >
              <MenuIcon />
            </IconButton>
          </div>

        </Toolbar>
      </AppBar>

      <MyCustomMenu />

      <Toolbar id="back-to-top-anchor" style={{ margin: 0, top: 0, position: 'absolute', zIndex: -1000 }} />

      <div className={classes.curvedCornerTopLeft}></div>
      <div className={classes.curvedCornerTopRight}></div>
      <div className={classes.topBanner}></div>

    </>
  );

};

export default MyAppBar;





