import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import useTranslation from '../i18n/LanguageHook';
import OrderDetailGrid from './OrderDetailGrid';
import { useUserContext } from '../UserContext';


const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    confirmButton: {
      alignSelf: 'center',
      fontWeight: 600,
      margin: theme.spacing(3, 1, 3, 1),
      borderRadius: '20em',
      color: theme.palette.success.contrastText,
      backgroundColor: theme.palette.success.main,
      transition: '0.3s',
      '&:hover': {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
      },
      "@media (pointer: fine)": {
        '&:hover': {
          backgroundColor: theme.palette.success.dark,
          color: theme.palette.success.contrastText,
        },
      },
    },
  }),
);

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const OrderConfirmDialog = ({ isOpen, onClose, onConfirm, confirming, confirmedOrder }) => {
  const { user }: any = useUserContext();
  const translation: any = useTranslation();
  const classes = useStyles();
  return (
    confirmedOrder ? (
      <div>
        <Dialog
          disableBackdropClick
          fullWidth={true}
          onClose={onClose}
          open={isOpen}
          aria-labelledby="customized-dialog-title"
        >
          <DialogTitle id="customized-dialog-title" onClose={onClose}>
            <Typography className={classes.title}>
              {user.company_name} <br />
              {translation.orders.order_summary}
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <OrderDetailGrid order={confirmedOrder} cancelOrder={onClose} singleColumn={true} />
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={onConfirm}
              disabled={confirming}
              className={classes.confirmButton}
            >
              {
                confirming
                  ? <>{translation.notification.please_wait}</>
                  : <>{translation.action.confirm_order}</>
              }
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    ) : null
  );
}

export default OrderConfirmDialog;