import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { itIT } from '@material-ui/core/locale';
import green from '@material-ui/core/colors/green';

const theme = createMuiTheme({
  palette: {
    primary: {
      // PDF versions
      // main: '#2190ff',
      // light: '#4da6ff',
      // dark: '#1764b2',
      // Definitivo version
      main: '#408bfd',
      light: '#66a2fd',
      dark: '#2c61b1',
      // Web app design
      // main: '#0093ff',
      // light: '#33a8ff',
      // dark: '#0066b2',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#00bcd4',
      light: '#33c9dc',
      dark: '#008394',

    },
    warning: {
      main: '#ffc107',
      light: '#ffcd38',
      dark: '#b28704',
      contrastText: '#ffffff',
    },
    error: {
      main: '#f50057',
      light: '#f73378',
      dark: '#ab003c',
      contrastText: '#ffffff',
    },
    success: {
      main: green[600],
      light: '#33eb91',
      dark: '#00a152',
      contrastText: '#ffffff',
    },
    background: {
      default: '#66a2fd',
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      mobile: 350,
      smartphone: 450,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    }
  },
  // typography: {
  //   fontFamily: [
  //     '-apple-system',
  //     'BlinkMacSystemFont',
  //     '"Segoe UI"',
  //     'Roboto',
  //     '"Helvetica Neue"',
  //     'Arial',
  //     'sans-serif',
  //     '"Apple Color Emoji"',
  //     '"Segoe UI Emoji"',
  //     '"Segoe UI Symbol"',
  //   ].join(','),
  // },
}, itIT);



ReactDOM.render(
  <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </>,
  document.getElementById('root')
);
