import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import * as React from 'react';
import { useEffect, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { components } from "react-select";
import { Address } from '../../types/types';
import useTranslation from '../i18n/LanguageHook';
import poweredByGoogle from '../Media/powered_by_google_on_white.png';
import { addressFormatting, googleAddressFormatting } from './utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orderConfirmGridAddress: {
      margin: theme.spacing(1, 0),
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      flex: '1 auto',
      minWidth: '100%',
      maxWidth: '100%',
      width: '100%',
    },
    orderConfirmAddress: {
      width: '100%',
      minHeight: '50px',
      minWidth: '100%',
    },
    orderConfirmAddressButton: {
      width: '100%',
      margin: theme.spacing(1, 0, 0),
      fontWeight: 600,
      maxWidth: '100%',
      borderRadius: '20em',
    },
    specialButton: {
      marginTop: theme.spacing(1),
      fontWeight: 600,
      width: '50%',
      display: 'inline-block',
      borderRadius: '20em',
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
      "@media (pointer: fine)": {
        '&:hover': {
          backgroundColor: theme.palette.error.dark,
        },
      },
    },
    addIcon: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '20em',
      // color: theme.palette.primary.main,
      fontWeight: 600,
    },
    inputIcon: {
      color: theme.palette.primary.main,
      borderRadius: '20em',
      // color: theme.palette.primary.main,
      fontWeight: 600,
    },
  })
);


export const AddressPicker = ({ addressList, childRef, setAddAddress, shippingAddress }) => {

  const classes = useStyles();
  const translation: any = useTranslation();
  const [selectedAddress, setSelectedAddress] = useState<string | undefined>("");

  // when the state changes locally, update the parent references with data and selected entry
  useEffect(() => {
    // const idx = addressList.findIndex((element: Address) => element.id === selectedAddress);
    // childRef.current = JSON.stringify(addressList[idx]);
    childRef.current = selectedAddress;
  }, [childRef, selectedAddress]);

  // set default with value from parent
  useEffect(() => {
    if (shippingAddress) {
      setSelectedAddress(shippingAddress);
    }
  }, [shippingAddress]);

  return (
    <>
      <Grid
        item
        className={classes.orderConfirmGridAddress}
      >
        <TextField
          select
          className={classes.orderConfirmAddress}
          label={translation.addresses.delivery_address}
          id="demo-simple-select-helper"
          variant="outlined"
          value={selectedAddress}
          onChange={(event: any) => setSelectedAddress(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" onClick={() => setAddAddress(true)}>
                <IconButton>
                  <AddCircleIcon
                    className={classes.addIcon}
                  />
                </IconButton>
              </InputAdornment>
            )
          }}
        >
          <span></span>
          {
            addressList.length !== 0 &&
            addressList.map((address: Address) => {
              const fullAddress = addressFormatting(address);
              return (
                <MenuItem key={address.id} id={address.id} value={address.id}>{fullAddress}</MenuItem>
              );
            })
          }
        </TextField>
      </Grid>
    </>
  );
};


export const AddressCreate = ({ childRef, setAddAddress }) => {

  const classes = useStyles();
  const [googleAddress, setGoogleAddress] = useState<any>({});

  useEffect(() => {
    if (googleAddress) {
      childRef.current = googleAddressFormatting(googleAddress);
    }
  });

  const Menu = (props: any) => {
    return (
      <>
        <components.Menu {...props}>
          {props.children}
          <div style={{ textAlign: 'center', zIndex: 999 }}>
            <img src={poweredByGoogle} alt="Google logo" />
          </div>
        </components.Menu>
      </>
    );
  };

  return (
    <>
      <Grid
        item
        className={classes.orderConfirmGridAddress}
      >
        <IconButton onClick={() => setAddAddress(false)} style={{ display: 'inline' }}>
          <ImportContactsIcon
            className={classes.inputIcon} />
        </IconButton>
        <GooglePlacesAutocomplete
          selectProps={{
            googleAddress,
            onChange: setGoogleAddress,
            components: {
              Menu,
            },
            menuPortalTarget: document.body,
            styles: {
              container: (provided) => ({
                ...provided,
                width: '100%',
              }),
              input: (provided) => ({
                ...provided,
                fontSize: 'clamp(0.75rem, 2vw, 1rem)',
                fontWeight: 600,
                width: '100%',
                minWidth: '100%',
                display: 'inline',
                margin: 0,
                lineHeight: '46px',
                minHeight: '50px',
                zIndex: 1000,
              })
            },
          }}
          apiOptions={{ language: 'it', region: 'it' }}
          autocompletionRequest={{
            componentRestrictions: {
              country: ['it'],
            },
            types: ["address"]
          }}
        />
      </Grid>

    </>
  );
};