import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Redirect, useHistory } from 'react-router-dom';
import { Address, Contact, Customer, Product } from '../../types/types';
import { cancel_order, confirm_order, get_addresses, get_contacts, get_user, send_order } from '../Api';
import { AddressCreate, AddressPicker } from '../Components/AddressPicker';
import { ContactCreate, ContactPicker } from '../Components/ContactPicker';
import DeliveryDatePicker from '../Components/DeliveryDatePicker';
import DeliveryInstructions from '../Components/DeliveryInstructions';
import IcePage from '../Components/IcePage';
import OrderConfirmDialog from '../Components/OrderConfirmDialog';
import ProductList from '../Components/ProductList';
import TimeslotPicker from '../Components/TimeslotPicker';
import useTranslation from '../i18n/LanguageHook';
import { timezoneStamp } from '../Components/utils';
import { useUserContext } from '../UserContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
    root: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    grow: {
      flexGrow: 1,
    },
    orderConfirmGrid: {
      width: '100%',
      maxWidth: '900px',
      display: 'grid',
      gridTemplateColumns: '1fr',
      padding: theme.spacing(1, 2, 2, 2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 1, 1, 1),
      },
      gridAutoRows: 'auto',
      justifyContent: 'start',
      alignItems: 'start',
      margin: 'auto',
      borderRadius: 12,
    },
    orderConfirmGridRow: {
      overflow: 'hidden',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      textAlign: 'center',
      gap: 8,
      padding: theme.spacing(0, 1, 0, 0),
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        margin: theme.spacing(1, 0, 0, 0),
        flexDirection: 'column',
        gap: 0,
      },
    },
    orderConfirmItem: {
      minWidth: '50%',
    },
    orderConfirmSubmit: {
      width: '100%',
      fontWeight: 600,
      margin: 0,
      borderRadius: '20em',
      transition: '0.3s',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      "@media (pointer: fine)": {
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.primary.contrastText,
        },
      },
    },
  }),
);


const OrderCreate = (props) => {
  const { user }: any = useUserContext();
  const classes = useStyles();
  const translation: any = useTranslation();
  const mobilescreen = useMediaQuery('(max-width:350px)');
  const smartphone = useMediaQuery('(max-width:600px)');
  const history = useHistory();

  // USER INFO
  const [userInfo, setUserInfo] = useState<Customer>();

  // ADDRESSES
  const [addressList, setAddressList] = useState<Array<Address>>([]); // List of existing
  const selectedAddress = useRef<string | undefined>(""); // select ID in that list
  const [addAddress, setAddAddress] = useState(false); // toggle to add a new
  const newAddress = useRef<Address | undefined>(); // object new
  const [saveAddress, setSaveAddress] = useState(false); // toggle to save that new

  useEffect(() => {
    if (addAddress === false) {
      setSaveAddress(false);
    }
  }, [addAddress, setSaveAddress]);


  // CONTACT INFO
  const [contactList, setContactList] = useState<Array<Contact>>([]); // List of existing
  const selectedContact = useRef<string | undefined>(""); // select ID in that list
  const [addContact, setAddContact] = useState(false); // toggle to add a new
  const newContact = useRef<Contact>(); // object new
  const [saveContact, setSaveContact] = useState(false); // toggle to save that new

  useEffect(() => {
    if (addContact === false) {
      setSaveContact(false);
    }
  }, [addContact, setSaveContact]);



  // OTHER INFO
  const cart = useRef<Array<Product>>([]);
  // const deliveryDate = useRef(new Date());
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const timeslot = useRef<string | undefined>("");
  const deliveryInstructions = useRef("");

  // DIALOG ORDER CONFIRM
  const [submitting, setSubmitting] = useState(false);
  const [confirmedOrder, setConfirmedOrder] = useState();
  const [showDialog, setShowDialog] = useState(true);
  const [confirming, setConfirming] = useState(false);


  // GET CUSTOMER INFORMATION
  const getCustomerInfo = () => {
    get_user()
      .then(response => {
        if (response?.status === 200) {
          setUserInfo(response.data);
        }
        else if (response?.status === 500) {
          // toast.error(translation.notification.http_error);
        }
        else {
          // Mute because customer has no addresses
        }
      })
      .catch(error => {
        // toast.error(translation.notification.http_error);
      });
  };

  useEffect(() => {
    return getCustomerInfo();
    // eslint-disable-next-line
  }, []);

  // GET CUSTOMER ADDRESSES BLOCK
  const getCustomerAddresses = () => {
    get_addresses()
      .then(response => {
        if (response?.status === 200) {
          setAddressList(response.data);
        }
        else if (response?.status === 500) {
          // toast.error(translation.notification.http_error);
        }
        else {
          // Mute because customer has no addresses
        }
      })
      .catch(error => {
        // toast.error(translation.notification.http_error);
      });
  };

  useEffect((): any => {
    return getCustomerAddresses()
    // eslint-disable-next-line
  }, []);

  // GET CUSTOMER CONTACTS BLOCK
  const getCustomerContacts = () => {
    get_contacts()
      .then(response => {
        if (response?.status === 200) {
          setContactList(response.data);
        }
        else if (response?.status === 500) {
          // toast.error(translation.notification.http_error);
        }
        else {
          // Mute because customer has no addresses
        }
      })
      .catch(error => {
        // toast.error(translation.notification.http_error);
      });
  };

  useEffect((): any => {
    return getCustomerContacts()
    // eslint-disable-next-line
  }, []);

  const handleDialogCancel = event => {
    setShowDialog(false);
    //@ts-ignore
    cancel_order(confirmedOrder?.id!)
      .then(() => {
        setSubmitting(false);
        setConfirming(false);
      })
      .catch(error => {
        // toast.error(translation.notification.http_error);
      });
  }

  const handleDialogConfirm = event => {
    setConfirming(true);
    //@ts-ignore
    confirm_order(confirmedOrder?.id!)
      .then(response => {
        if (response?.status === 200) {
          setShowDialog(false);
          toast.success(`${translation.notification.order_thank_you}`, { duration: 10000 });
          history.push('/orders');
          setSubmitting(false);
          setConfirming(false);
        }
        else if (response?.status === 500) {
          // toast.error(translation.notification.http_error);
        }
        else {
          // Mute because customer has no addresses
        }
      })
      .catch(error => {
        // toast.error(translation.notification.http_error);
      });
  }

  const checkOrder = event => {
    event.preventDefault();
    setSubmitting(true);
    let valid = true;

    const validOrder = {};

    // testing for empty contact name
    if (addContact && !newContact?.current?.name) {
      valid = false;
    }

    // testing for empty telephone
    if (addContact && (
      !newContact?.current?.telephone ||
      newContact?.current?.telephone === "" ||
      newContact?.current?.telephone.slice(0, 3) !== "+39"
    )) {
      valid = false;
    }

    // testing for no selected contact
    if (!addContact && !selectedContact.current) {
      valid = false;
    }

    // adding the values to the order object
    if (addContact) {
      validOrder["contact_name"] = newContact?.current?.name;
      validOrder["telephone"] = newContact?.current?.telephone;
    } else {
      const idx = contactList.findIndex((element: Contact) => element.id === selectedContact.current);
      if (contactList[idx]) {
        validOrder["contact_name"] = contactList[idx]?.name;
        validOrder["telephone"] = contactList[idx]?.telephone;
      }
      else {
        valid = false;
        toast.error(translation.notification.contact_not_selected);
        setSubmitting(false);
      }
    }

    // if creating a new address we want the address object to not be empty
    if (addAddress && !newAddress?.current?.street) {
      valid = false;
    }

    // if selecting an address we want the ID to not be empty
    if (!addAddress && !selectedAddress) {
      valid = false;
    }

    // Adding the address to the Order object
    if (addAddress) {
      validOrder["shipping_address"] = JSON.stringify(newAddress!.current!);
    } else {
      const idx = addressList.findIndex((element: Address) => element.id === selectedAddress.current);
      validOrder["shipping_address"] = JSON.stringify(addressList[idx])
    }

    // we want the date to be picked
    // console.log(deliveryDate.current);
    if (deliveryDate === null) {
      valid = false;
      toast.error(translation.notification.selected_date_not_valid);
      setSubmitting(false);
    }
    validOrder["delivery_date"] = timezoneStamp(deliveryDate);


    // we want the timeslot to be picked
    if (!timeslot.current || timeslot.current === "") {
      valid = false;
      toast.error(translation.notification.timeslot_not_valid);
      setSubmitting(false);
    }
    validOrder["timeslot_id"] = timeslot.current


    // we want to have a final object with non empty items of the cart
    let orderTotal = 0;
    let orderItems: Array<Product> = [];
    let orderQuantity = 0;
    cart.current.map(orderItem => {
      if (orderItem.quantity > 0) {
        orderItems.push(orderItem);
        orderTotal += orderItem.net_price * orderItem.quantity;
        orderQuantity += orderItem.quantity;
      }
      return true
    });
    // we want to have a final price that is not 0
    // console.log(orderTotal);
    if (orderTotal <= 0) {
      valid = false;
      toast.error(translation.notification.cart_not_valid);
      setSubmitting(false);
    }
    validOrder["taxable_amount"] = orderTotal

    // we want to have a final item count that is not 0
    // console.log(orderQuantity);
    if (orderQuantity <= 0) {
      valid = false;
      setSubmitting(false);
    }
    // we want to have a final item quantity that is not 0 in basket
    // console.log(orderItems.length);
    if (orderItems.length === 0) {
      valid = false;
      setSubmitting(false);
    }
    validOrder["basket"] = JSON.stringify(orderItems);
    validOrder["delivery_instructions"] = deliveryInstructions.current


    if (saveContact) {
      validOrder["save_contact"] = true;
    }
    if (saveAddress) {
      validOrder["save_address"] = true;
    }

    if (!valid) {
      setSubmitting(false);
    }

    if (valid) {
      send_order(validOrder)
        .then(response => {
          if (response?.status === 200) {
            setConfirmedOrder(response.data);
            setShowDialog(true);
          }
          else {
            // toast.error(translation.notification.http_error);
          }
        })
        .catch(error => {
          // toast.error(translation.notification.http_error);
        })
        .finally(() => {
          setSubmitting(false);
        })
    }

  };

  if (!user) {
    return <Redirect to="/login" />;
  };

  return (
    <IcePage>

      <ProductList
        childRef={cart}
        taxPercentage={userInfo?.split_payment ? 0 : 22}
      />

      <Box my={3}>
        <Grid
          container
          spacing={0}
          component={Paper}
          className={classes.orderConfirmGrid}
          elevation={smartphone ? 0 : 5}
        >

          {/* FIRST ROW */}
          <Grid item xs={12} className={classes.orderConfirmGridRow}>
            <Grid item xs={12} md={6} className={classes.orderConfirmItem}>
              {
                addContact
                  ? (
                    <>
                      <ContactCreate
                        childRef={newContact} // child gives back a Contact object
                        setAddContact={setAddContact} // child can disable its state when finished
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={saveContact}
                            onChange={() => setSaveContact(!saveContact)}
                            name="saveContact"
                            color="primary"
                          />
                        }
                        label={translation.contacts.save_contact}
                      />
                    </>
                  ) : (
                    <>
                      <ContactPicker
                        contactList={contactList} // parent sends the list to child
                        childRef={selectedContact} // child sends back the ID of the value selected
                        setAddContact={setAddContact} //child can toggle its state to create
                        mainContact={userInfo?.main_contact} // initial value given by parent
                      />
                    </>
                  )
              }
            </Grid>
            <Grid item xs={12} md={6} className={classes.orderConfirmItem}>
              {
                addAddress
                  ? (
                    <>
                      <AddressCreate
                        childRef={newAddress} // child gives back a Address object
                        setAddAddress={setAddAddress} // child can disable its state when finished
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={saveAddress}
                            onChange={() => setSaveAddress(!saveAddress)}
                            name="saveAddress"
                            color="primary"
                          />
                        }
                        label={translation.addresses.save_address}
                      />
                    </>
                  ) : (
                    <>
                      <AddressPicker
                        addressList={addressList} // parent sends the list to child
                        childRef={selectedAddress} // the ID
                        setAddAddress={setAddAddress} // child can toggle its create state on
                        shippingAddress={userInfo?.shipping_address} // initial value given by parent
                      />
                    </>
                  )
              }
            </Grid>
          </Grid>

          {/* SECOND ROW */}
          <Grid item xs={12} className={classes.orderConfirmGridRow}>

            <Grid item xs={12} md={6} className={classes.orderConfirmItem}>
              <DeliveryDatePicker
                // childRef={deliveryDate} // child sends back the date as a Ref
                deliveryDate={deliveryDate} // instead of ref use a state
                setDeliveryDate={setDeliveryDate} // instead of ref use a state
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.orderConfirmItem}>
              <TimeslotPicker childRef={timeslot} deliveryDate={deliveryDate} />
            </Grid>
          </Grid>


          {/* THIRD ROW */}
          <Grid item xs={12}>
            <Grid item xs={12}>
              <DeliveryInstructions childRef={deliveryInstructions} />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                size={mobilescreen ? "small" : "large"}
                className={classes.orderConfirmSubmit}
                disabled={submitting}
                onClick={checkOrder}
              >
                {
                  submitting
                    ? <>{translation.notification.please_wait}</>
                    : <>{translation.action.calculate_costs}</>
                }
              </Button>
            </Grid>
          </Grid>

        </Grid>
      </Box>

      <OrderConfirmDialog
        isOpen={showDialog}
        onConfirm={event => handleDialogConfirm(event)}
        onClose={event => handleDialogCancel(event)}
        confirmedOrder={confirmedOrder}
        confirming={confirming}
      />

    </IcePage >
  );

};

export default OrderCreate;





