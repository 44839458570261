import axios from 'axios';
import rateLimit from 'axios-rate-limit';

// axios.defaults.baseURL = "https://127.0.0.1:5000";
//axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

export const axiosHttp = rateLimit(axios.create(), { maxRequests: 2, perMilliseconds: 1000, maxRPS: 2 });
let axios_token = '';

axiosHttp.defaults.withCredentials = true;
axiosHttp.defaults.baseURL = '/api';
// axiosHttp.defaults.baseURL = 'http://localhost:8000';


// Interceptor when not authenticated

axiosHttp.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (
    (
      error?.status === 401
      || error?.status === 403
      || error?.status === 422
    )
    && !originalRequest._retry
    && originalRequest.url !== "/auth/refresh"
  ) {
    originalRequest._retry = true;
    axiosHttp.get('/auth/refresh')
      .then(response => {
        if (response?.status === 200) {
          axios_token = response.data["access_token"];
        }
      })
      .catch(console.log);
    return axiosHttp(originalRequest);
  }
  return Promise.reject(error);
});

// Route not protected

export const login = (user: any) => {
  const origin = "shop";
  return axiosHttp
    .post('/auth/login', { ...user, origin })
    .then(response => {
      if (response && response?.status === 200) {
        axios_token = response.data['access_token'];
        return response;
      }
    })
    .catch(error => { return error.response });
};


export const logout = () => {
  return axiosHttp
    .delete('/auth/logout',
      { headers: { 'Authorization': `Bearer ${axios_token}` } }
    )
    .catch(error => { return error.response });
};


export const refresh = () => {
  return axiosHttp
    .get('/auth/refresh')
    .then(response => {
      if (response && response?.status === 200) {
        axios_token = response.data['access_token'];
        return response;
      }
    })
    .catch(error => { return error.response });
};

// CRUD

export const get_products = () => {
  return axiosHttp
    .get('/shop/products',
      { headers: { 'Authorization': `Bearer ${axios_token}` } }
    )
    .then((response: any) => {
      if (response?.status === 200) {
        return response;
      } else {
        throw new Error();
      }
    })
    .catch(error => { return error.response });
};


export const get_orders = (params) => {
  return axiosHttp
    .post('/shop/orders/list', params,
      { headers: { 'Authorization': `Bearer ${axios_token}` } }
    )
    .then((response: any) => {
      if (response?.status === 200) {
        return response;
      } else {
        throw new Error();
      }
    })
    .catch(error => { return error.response });
};


export const get_order = orderId => {
  return axiosHttp
    .get(`/shop/orders/${orderId}`,
      { headers: { 'Authorization': `Bearer ${axios_token}` } }
    )
    .then((response: any) => {
      if (response?.status === 200) {
        return response;
      } else {
        throw new Error();
      }
    })
    .catch(error => { return error.response });
};


export const send_order = validOrder => {
  return axiosHttp
    .post('/shop/orders', validOrder,
      { headers: { 'Authorization': `Bearer ${axios_token}` } }
    )
    .then((response: any) => {
      if (response?.status === 200) {
        return response;
      } else {
        throw new Error();
      }
    })
    .catch(error => { return error.response });
};


export const cancel_order = id => {
  return axiosHttp.delete(`/shop/orders/${id}`,
    { headers: { 'Authorization': `Bearer ${axios_token}` } }
  )
    .then((response: any) => {
      if (response?.status === 200) {
        return response;
      } else {
        throw new Error();
      }
    })
    .catch(error => { return error.response });
};

export const confirm_order = id => {
  console.log(id);
  return axiosHttp.post(`/shop/orders/confirm/${id}`, "True",
    { headers: { 'Authorization': `Bearer ${axios_token}` } }
  )
    .then((response: any) => {
      if (response?.status === 200) {
        return response;
      } else {
        throw new Error();
      }
    })
    .catch(error => { return error.response });
};


export const get_addresses = () => {
  return axiosHttp
    .get('/shop/addresses',
      { headers: { 'Authorization': `Bearer ${axios_token}` } }
    )
    .then((response: any) => {
      if (response?.status === 200) {
        return response;
      } else {
        throw new Error();
      }
    })
    .catch(error => { return error.response });
};


export const save_address = newAddress => {
  return axiosHttp.post('/shop/addresses', newAddress,
    { headers: { 'Authorization': `Bearer ${axios_token}` } }
  )
    .then((response: any) => {
      if (response?.status === 200) {
        return response;
      } else {
        throw new Error();
      }
    })
    .catch(error => { return error.response });
};


export const delete_address = id => {
  return axiosHttp.delete(`/shop/addresses/${id}`,
    { headers: { 'Authorization': `Bearer ${axios_token}` } }
  )
    .then((response: any) => {
      if (response?.status === 200) {
        return response;
      } else {
        throw new Error();
      }
    })
    .catch(error => { return error.response });
};


export const update_user = newUserInfo => {
  return axiosHttp.put('/shop/userinfo', newUserInfo,
    { headers: { 'Authorization': `Bearer ${axios_token}` } }
  )
    .then((response: any) => {
      if (response?.status === 200) {
        return response;
      } else {
        throw new Error();
      }
    })
    .catch(error => { return error.response });
};


export const get_user = () => {
  return axiosHttp.get('/shop/userinfo',
    { headers: { 'Authorization': `Bearer ${axios_token}` } }
  )
    .then((response: any) => {
      if (response?.status === 200) {
        return response;
      } else {
        throw new Error();
      }
    })
    .catch(error => { return error.response });
};


export const get_contacts = () => {
  return axiosHttp
    .get('/shop/contacts',
      { headers: { 'Authorization': `Bearer ${axios_token}` } }
    )
    .then((response: any) => {
      if (response?.status === 200) {
        return response;
      } else {
        throw new Error();
      }
    })
    .catch(error => { return error.response });
};


export const save_contact = newContact => {
  return axiosHttp.post('/shop/contacts', newContact,
    { headers: { 'Authorization': `Bearer ${axios_token}` } }
  )
    .then((response: any) => {
      if (response?.status === 200) {
        return response;
      } else {
        throw new Error();
      }
    })
    .catch(error => { return error.response });
};


export const delete_contact = id => {
  return axiosHttp.delete(`/shop/contacts/${id}`,
    { headers: { 'Authorization': `Bearer ${axios_token}` } }
  )
    .then((response: any) => {
      if (response?.status === 200) {
        return response;
      } else {
        throw new Error();
      }
    })
    .catch(error => { return error.response });
};

export const get_timeslots = (delivery_date) => {
  return axiosHttp
    .get(`/shop/timeslots/${delivery_date}`,
      { headers: { 'Authorization': `Bearer ${axios_token}` } }
    )
    .then((response: any) => {
      if (response?.status === 200) {
        return response;
      } else {
        throw new Error();
      }
    })
    .catch(error => { return error.response });
};
